<template>
  <div class="animated fadeIn">
    <b-card>
      projectId: {{ projectId }}
      <div class="card-body">
        <b-overlay
          :show="isLoading"
          :opacity="0.5"
          spinner-variant="secondary"
          rounded="sm"
        >
          <b-tabs content-class="mt-3" justified>
            <b-tab title="Overview" active>
              <form>
                <h5 v-if="mode === FORM_MODE.CREATE">
                  New Task
                </h5>
                <h5 v-if="mode !== FORM_MODE.CREATE">Task #{{ data.id }}</h5>
                <hr />
                <b-row>
                  <b-col lg="4" md="4">
                    <inline-input
                      :id="controls.task_name.id"
                      :value="data.task_name"
                      :label="controls.task_name.label"
                      :readonly="controls.task_name.readonly"
                      :mode="mode"
                      :required="true"
                      @changed="updateField"
                    />
                  </b-col>
                  <b-col lg="2" md="2">
                    <inline-switch
                      :id="controls.activity_reminder.id"
                      :value="data.activity_reminder"
                      :readonly="false"
                      :label="controls.activity_reminder.label"
                      @changed="onActivityReminderChange"
                    />
                  </b-col>

                  <b-col lg="2" md="2">
                    <inline-select
                      :id="controls.is_private.id"
                      :value="data.is_private"
                      :label="controls.is_private.label"
                      :readonly="controls.is_private.readonly"
                      :options="controls.is_private.options"
                      :mode="mode"
                      @changed="updateSelect"
                    />
                  </b-col>
                  <b-col lg="2" md="6">
                    <inline-select
                      :id="controls.recurring_type.id"
                      :value="data.recurring_type"
                      :label="controls.recurring_type.label"
                      :readonly="data.recurring_type.readonly"
                      :options="controls.recurring_type.options"
                      :allow-empty="true"
                      :mode="mode"
                      @changed="updateSelect"
                      :required="false"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="4" md="6">
                    <inline-select
                      :id="controls.account.id"
                      :value="data.account"
                      :label="controls.account.label"
                      :readonly="controls.account.readonly"
                      :options="controls.account.options"
                      :allow-empty="false"
                      :mode="mode"
                      @changed="updateSelect"
                      :required="data.activity_reminder"
                    />
                  </b-col>
                  <b-col lg="2" md="6">
                    <inline-date-picker
                      :id="controls.date_start.id"
                      :value-single="data.date_start"
                      :label="controls.date_start.label"
                      :readonly="controls.date_start.readonly"
                      :mode="mode"
                      @changed="updateDateField"
                    />
                  </b-col>
                  <b-col lg="2" md="6">
                    <inline-date-picker
                      :id="controls.due_date.id"
                      :value-single="data.due_date"
                      :label="controls.due_date.label"
                      :readonly="controls.due_date.readonly"
                      :mode="mode"
                      @changed="updateDateField"
                    />
                  </b-col>
                  <b-col lg="2" md="6">
                    <inline-select
                      :id="controls.status.id"
                      :value="data.status"
                      :label="controls.status.label"
                      :readonly="data.activity_reminder"
                      :options="controls.status.options"
                      :allow-empty="false"
                      :mode="mode"
                      @changed="updateSelect"
                      :required="true"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="2" md="6">
                    <inline-select
                      :id="controls.priority.id"
                      :value="data.priority"
                      :label="controls.priority.label"
                      :readonly="controls.priority.readonly"
                      :options="controls.priority.options"
                      :allow-empty="false"
                      :mode="mode"
                      @changed="updateSelect"
                      :required="true"
                    />
                  </b-col>
                  <b-col lg="2" md="6">
                    <inline-select
                      :id="controls.assigned_to.id"
                      :value="data.assigned_to"
                      :label="controls.assigned_to.label"
                      :group_values="'group_values'"
                      :group_label="'group_label'"
                      :group-select="true"
                      :readonly="controls.assigned_to.readonly"
                      :options="controls.assigned_to.options"
                      :allow-empty="true"
                      :multiple="true"
                      :mode="mode"
                      @changed="updateSelect"
                      :required="true"
                    />
                  </b-col>
                  <b-col lg="4" md="6">
                    <inline-select
                      :id="controls.project.id"
                      :value="data.project"
                      :label="controls.project.label"
                      :readonly="controls.project.readonly"
                      :options="controls.project.options"
                      :allow-empty="false"
                      :mode="mode"
                      @changed="updateSelect"
                      :required="false"
                      :link-mode="true"
                      @link-clicked="onProjectLinkClicked"
                    />
                  </b-col>
                  <b-col lg="2" md="6">
                    <inline-switch
                      :id="controls.sendnotification.id"
                      :value="data.sendnotification"
                      :readonly="false"
                      :label="
                        `Remind on ${
                          data.recurring_type.id ? 'Start Date' : 'Due Date'
                        }`
                      "
                      @changed="updateField"
                    />
                  </b-col>
                </b-row>

                <b-row>
                  <b-col lg="12">
                    <label class="col-form-label" :for="controls.editor.id"
                      >Description</label
                    >
                    <ckeditor
                      ref="ckeditor"
                      :id="controls.editor.id"
                      :editor="editor"
                      :disabled="controls.editor.readonly"
                      v-model="data.description"
                      :config="controls.editor.config"
                      @blur="onEditorBlur"
                    />
                  </b-col>
                </b-row>
              </form>
            </b-tab>
            <b-tab
              :title="controls.tabs.files.title"
              v-if="mode !== FORM_MODE.CREATE"
            >
              <files-container
                :module-id="$route.meta.module.id"
                :entity-id="id"
                @loaded="onFileContainerLoad"
              />
            </b-tab>
            <b-tab
              :title="controls.tabs.dataHistory.title"
              v-if="mode !== $constants.FORM_MODE.CREATE"
            >
              <data-history-table
                module="tasks"
                :entity-id="id"
                @loaded="onDataHistoryLoad"
              />
            </b-tab>
          </b-tabs>
        </b-overlay>

        <hr />

        <!-- form view -->
        <form-submission-actions
          v-if="!createTaskAction"
          :mode="mode"
          :loading="{
            save: saveInProgress,
            saveAndView: saveAndViewInProgress
          }"
          :buttons-visibility="{
            previous: $customTable.getPrevKey($route.meta.module, id),
            next: $customTable.getNextKey($route.meta.module, id)
          }"
          @previous-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getPrevKey($route.meta.module, id)
              }
            })
          "
          @next-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getNextKey($route.meta.module, id)
              }
            })
          "
          @save="
            save('back').then(response =>
              response ? $router.push({ name: 'Tasks' }) : false
            )
          "
          @save-and-view="
            save('view').then(response =>
              response
                ? $router.push({
                    name: 'Task submission',
                    params: { action: 'view', id: response }
                  })
                : false
            )
          "
          @edit="
            $router.push({
              name: 'Task submission',
              params: { action: 'edit', id: id }
            })
          "
          @back="$router.push($store.getters['router/previousRoute'])"
          @custom-click="
            name => {
              this[name]();
            }
          "
          :custom-buttons="customButtons"
        />

        <!-- modal view -->
        <form-submission-actions
          v-if="createTaskAction && action !== 'preview'"
          :mode="mode"
          :hide-regular-buttons="true"
          @custom-click="
            name => {
              this[name]();
            }
          "
          :custom-buttons="[
            {
              text: 'Save',
              icon: 'save',
              loading: saveAndViewInProgress,
              visible: true,
              method: 'saveTaskModal',
              variant: 'outline-dark'
            },
            {
              text: 'Close',
              icon: 'times',
              loading: false,
              visible: true,
              method: 'closeTaskModal',
              variant: 'outline-dark'
            }
          ]"
        />
      </div>
    </b-card>

    <project-preview-modal ref="project-preview-modal"></project-preview-modal>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";

import InlineInput from "@/components/InlineInput";
import InlineSelect from "@/components/InlineSelectMultiple";
import InlineDatePicker from "@/components/InlineDatePicker";
import InlineSwitch from "@/components/InlineSwitch";

import ProjectPreviewModal from "./ProjectPreviewModal";

import FormSubmissionActions from "@/components/FormSubmissionActions";

import CKEditor from "@ckeditor/ckeditor5-vue";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";
Vue.use(CKEditor);

import { FORM_MODE, FORMCONTROLMODE, MODULES } from "@/shared/constants";

import FilesContainer from "@/components/FilesContainer";

import { mapState, mapActions } from "vuex";

import { getPostponedDates } from "./helpers";

import DataHistoryTable from "@/components/DataHistoryTable";

export default {
  name: "TaskSubmissionForm",

  props: {
    id: {
      type: [Number, String],
      required: false,
      default: ""
    },
    action: {
      type: String,
      required: true
    },
    createTaskAction: {
      type: Boolean,
      required: false
    },
    properties: {
      type: Object,
      required: false,
      default: () => {}
    },
    projectId: {
      type: Number,
      required: false,
      default: () => {}
    }
  },
  components: {
    InlineInput,
    InlineSelect,
    InlineDatePicker,
    ckeditor: CKEditor.component,
    FilesContainer,
    FormSubmissionActions,
    InlineSwitch,
    ProjectPreviewModal,
    DataHistoryTable
  },
  data: function() {
    return {
      editor: InlineEditor,
      //editor: ClassicEditor,
      isLoading: false,
      isReviewer: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      MODULES: MODULES,
      FORM_MODE: FORM_MODE,

      controls: {
        tabs: {
          files: {
            title: "Files"
          },
          dataHistory: {
            title: "Data History"
          }
        },
        buttons: {
          edit: {
            id: "button:details_edit"
          },
          save: {
            id: "button:details_save"
          }
        },
        task_name: {
          id: "input:task_name",
          label: "Task Name",
          changed: false,
          readonly: false
        },
        is_private: {
          id: "select:is_private",
          label: "Private task",
          options: [
            {
              id: "0",
              label: "No"
            },
            {
              id: "1",
              label: "Yes"
            }
          ],
          changed: false,
          readonly: false
        },

        date_start: {
          id: "datepicker:date_start",
          label: "Start Date",
          changed: false,
          readonly: false
        },
        due_date: {
          id: "datepicker:due_date",
          label: "Due Date",
          changed: false,
          readonly: false
        },

        status: {
          id: "select:status",
          label: "Status",
          options: [],
          changed: false,
          readonly: false
        },
        priority: {
          id: "select:priority",
          label: "Priority",
          options: [],
          changed: false,
          readonly: false
        },

        assigned_to: {
          id: "select:assigned_to",
          label: "Assigned To",
          options: [],
          changed: false,
          readonly: false
        },
        account: {
          id: "select:account",
          label: "Account",
          options: [],
          changed: false,
          readonly: false
        },
        project: {
          id: "select:project",
          label: "Project",
          options: [],
          changed: false,
          readonly: false
        },
        recurring_type: {
          id: "select:recurring_type",
          label: "Reccuring type",
          options: [],
          changed: false,
          readonly: false
        },
        description: {
          id: "input:description",
          label: "Description",
          changed: false,
          readonly: false
        },
        editor: {
          data: "",
          config: {
            startupFocus: true,
            startupShowBorders: true
            // readOnly: true,
          },
          readonly: false
        },
        activity_reminder: {
          id: "switch:activity_reminder",
          label: "Activity reminder",
          value: false,
          changed: false,
          readonly: false
        },
        sendnotification: {
          id: "switch:sendnotification",
          label: "Remind on Due Date",
          value: false,
          changed: false,
          readonly: false
        }
      },
      data: {
        id: "",
        task_name: "",
        date_start: "",
        due_date: "",
        is_private: {
          id: "",
          label: ""
        },
        status: {
          id: "",
          label: ""
        },
        priority: {
          id: "",
          label: ""
        },
        recurring_type: {
          id: null,
          label: ""
        },
        assigned_to: [],
        description: "",
        activity_reminder: false,
        sendnotification: true,
        action: "",
        category: {
          id: "",
          label: ""
        }
      },
      actionObject: null
    };
  },

  computed: {
    ...mapState({ profile: state => state.profile }),
    customButtons() {
      let btnArray = [
        {
          text: "Postpone",
          icon: "clock",
          loading: false,
          visible: !(
            this.mode === FORM_MODE.CREATE || this.data.recurring_type.id == ""
          ),
          method: "postpone",
          variant: "outline-dark"
        },
        {
          text: "Create",
          icon: "plus",
          loading: false,
          visible: !(this.mode === FORM_MODE.CREATE),
          method: "create",
          variant: "outline-dark"
        }
      ];

      //if (this.actionObject !== null) {
      if (this.data.activity_reminder) {
        let actionBtn = {
          text: this.actionObject.title,
          icon: "file",
          loading: false,
          visible: !(this.mode === FORM_MODE.CREATE),
          method: "onActionClick",
          variant: "primary"
        };

        btnArray.unshift(actionBtn);
      }

      return btnArray;
    }
  },
  created() {
    this.initialize();
  },
  mounted: function() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    onFileContainerLoad(count) {
      if (count > 0) this.controls.tabs.files.title = `Files (${count})`;
    },
    initialize: async function() {
      this.isLoading = true;

      if (this.action == "create") {
        this.mode = this.FORM_MODE.CREATE;
      }
      if (this.action == "edit") {
        this.mode = this.FORM_MODE.EDIT;
      }
      if (this.action == "view") {
        this.mode = this.FORM_MODE.VIEW;
      }
      if (this.action == "preview") {
        this.mode = this.FORM_MODE.VIEW;
      }

      //this.updateControlsState();

      this.loadDictionaries();

      if (this.mode !== this.FORM_MODE.CREATE && this.id) {
        this.fetchData(this.id);
      }

      //this.data.id = this.id;
      //this.data.task_name =  `${this.profile.data.first_name} ${this.profile.data.last_name}`;

      if (this.mode === this.FORM_MODE.CREATE && !this.createTaskAction) {
        this.data.date_start = moment.utc().format("YYYY-MM-DD");
        this.data.due_date = moment.utc().format("YYYY-MM-DD");

        this.data.is_private = this.controls.is_private.options.find(
          m => m.id == "0"
        );

        this.data.status = {
          id: "Not Started",
          label: "Not Started"
        };
        this.data.priority = {
          id: "Medium",
          label: "Medium"
        };

        //let u = this.controls.assigned_to.options.find( i => i.group_label === 'Users')
        //this.data.assigned_to.options.push(u.group_values.find(t => t.id === this.profile.data.id.toString()))

        this.data.assigned_to.push({
          id: this.profile.data.id.toString(),
          label:
            this.profile.data.first_name + " " + this.profile.data.last_name
        });

        this.isLoading = false;
      }

      if (this.mode === this.FORM_MODE.CREATE && this.createTaskAction) {
        this.data.task_name = this.properties.task_name;
        this.data.description = this.properties.description;

        this.data.date_start = this.properties.date_start;
        this.data.due_date = this.properties.date_end;

        this.data.is_private = this.controls.is_private.options.find(
          m => m.id == "0"
        );

        this.data.status = this.properties.status;
        this.data.priority = this.properties.priority;

        this.data.assigned_to.push({
          id: this.profile.data.id.toString(),
          label:
            this.profile.data.first_name + " " + this.profile.data.last_name
        });

        this.data.account = this.properties.account;

        //read passed action JSON
        this.actionObject = this.properties.action;
        this.data.action = JSON.stringify(this.properties.action);

        this.data.activity_reminder = true;

        this.isLoading = false;
      }
    },
    async loadDictionaries() {
      this.$api.get("tasks/statuses").then(response => {
        this.controls.status.options = response.map(item => {
          return {
            id: item.id,
            label: item.name
          };
        });
      });

      this.$api.get("tasks/priorities").then(response => {
        this.controls.priority.options = response.map(item => {
          return {
            id: item.id,
            label: item.name
          };
        });
      });

      let users = await this.$api.get("users");
      let groups = await this.$api.get("groups");

      this.controls.assigned_to.options = [
        {
          group_label: "Groups",
          group_values: groups.map(u => ({
            id: u.groupid.toString(),
            label: u.groupname
          }))
        },
        {
          group_label: "Users",
          group_values: users.map(u => ({
            id: u.id.toString(),
            label: u.full_name
          }))
        }
      ];

      this.$api.get("accounts").then(response => {
        this.controls.account.options = this.$helpers.getDistinctArray(
          response,
          "ID",
          "Account Name",
          "id",
          "label"
        );
      });

      this.$api.post("dictionaries/projects", { active: 1 }).then(response => {
        this.controls.project.options = this.$helpers.getDistinctArray(
          response,
          "id",
          "name",
          "id",
          "label"
        );

        if (this.mode === FORM_MODE.CREATE && this.projectId > 0) {
          this.data.project = this.controls.project.options.find(
            p => p.id == this.projectId
          );
        }
      });

      this.$api.get("tasks/recurringtypes").then(response => {
        this.controls.recurring_type.options = response.map(u => ({
          id: u.name,
          label: u.name
        }));
      });
    },
    postpone() {
      let { startDateNew, dueDateNew } = getPostponedDates(
        this.data.date_start,
        this.data.due_date,
        this.data.recurring_type.id
      );

      this.data.date_start = startDateNew.format("YYYY-MM-DD");
      this.data.due_date = dueDateNew.format("YYYY-MM-DD");

      this.controls["date_start"].changed = true;
      this.controls["due_date"].changed = true;

      //if control in View mode then update database
      if (this.mode === FORMCONTROLMODE.VIEW) {
        let self = this;

        let params = {};
        params["date_start"] = this.data.date_start;
        params["due_date"] = this.data.due_date;

        self.$api
          .put(`tasks/${this.id}`, params)
          .then(() => {
            self.$form.makeToastInfo(`Task has been postponed`);

            self.controls["date_start"].changed = false;
            self.controls["due_date"].changed = false;
            this.initialize();
          })
          .catch(response => {
            console.log(response);

            self.$form.makeToastError(`${response.message}`);
          });
      }
    },
    create() {
      this.$router.push({
        name: "Task submission",
        params: {
          action: "create"
        }
      });
    },
    save(_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");

        return Promise.resolve(false);
      }

      //save subject, description, account into action property as json
      this.updateActionData();

      this.saveInProgress = _mode == "back" || _mode == "tabular";
      this.saveAndViewInProgress = _mode == "view";

      let self = this;
      let url = "";
      let method = "";

      if (this.mode === this.FORM_MODE.CREATE) {
        method = "put";
        url = "tasks";
      } else {
        method = "put";
        url = `tasks/${this.data.id}`;
      }

      return this.$api[method](url, this.data)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          console.log(error);

          self.$form.makeToastError(error.message);

          return Promise.resolve(false);
        });
    },
    onActionClick() {
      this.actionObject.params.task_id = this.id;

      this.$router.push({
        name: "Activity submission",
        params: {
          action: "create",
          //new activity default values
          params: this.actionObject.params
        }
      });
    },
    saveTaskModal() {
      this.save("view").then(response =>
        response ? this.$emit("save", response) : false
      );
    },
    closeTaskModal() {
      this.$emit("close");
    },

    onEditorBlur() {
      if (this.mode !== this.FORM_MODE.VIEW) return;

      this.updateField("description", this.data.description);
    },
    updateDateField(e) {
      this.updateField(e.id, e.valueSingleAsString);
    },
    onActivityReminderChange(id, value) {
      this.data.activity_reminder = value;
      this.updateActionData();

      this.updateField("activity_reminder", value);
    },

    updateActionData() {
      if (this.data.activity_reminder) {
        let action = {
          title: "Submit activity",
          //method declared in the TaskSubmissionForm.vue
          method: "onActionClick",
          params: {
            //task does not have activity categories dropdown so it will be used only if passed from the activities in props
            category_id: this.actionObject
              ? this.actionObject.params.category_id
              : "",
            account_id: this.data.account ? this.data.account.id : "",
            subject: this.data.task_name,
            description: this.data.description
          }
        };

        this.data.action = JSON.stringify(action);

        this.actionObject = action;

        this.data.status = {
          id: "Planned",
          label: "Planned"
        };
      } else {
        this.data.action = null;

        //do not reset actionObject to keep passed activity Category during switching activity reminder switch
        //this.actionObject = null;
      }
    },
    updateField(field, value) {
      let self = this;
      let params = {};
      params["id"] = this.id;
      params[field] = value;

      //update local data values - it can be used in Edit action
      self.data[field] = value;

      //save subject, description, account into action field as json
      if (this.data.activity_reminder) {
        this.updateActionData();
        params["action"] = this.data.action;
      } else {
        params["action"] = null;
      }

      //if control in View mode then update database
      if (this.mode === FORMCONTROLMODE.VIEW)
        self.$api
          .put(`tasks/${this.id}`, params)
          .then(response => {
            self.$form.makeToastInfo(`${field}: ${response.message}`);

            self.controls[field].changed = false;

            this.initialize();
          })
          .catch(response => {
            console.log(response);

            self.$form.makeToastError(`${field}: ${response.message}`);
          });
    },
    updateSelect(id, value) {
      if (id == "recurring_type") {
        if (value.id !== "") this.data.sendnotification = false;
        else this.data.sendnotification = true;
      }

      this.updateField(id, value);
    },
    async fetchData(id) {
      let self = this;

      return this.$api
        .get(`tasks/${id}`)
        .then(response => {
          self.isLoading = false;
          if (self.$_.isEmpty(response)) {
            self.$form.msgBoxOk("No data available");
            return;
          }

          self.data.is_private = {
            id: self.controls.is_private.options.find(
              m => m.id == response.is_private
            ).id,
            label: self.controls.is_private.options.find(
              m => m.id == response.is_private
            ).label
          };

          self.data.status = {
            id: response.Status,
            label: response.Status
          };

          self.data.priority = {
            id: response.Priority,
            label: response.Priority
          };

          self.data.account = {
            id: response["Account ID"],
            label: response["Account Name"]
          };

          self.data.project = {
            id: response["Project ID"],
            label: response["Project Name"]
          };

          if (response["assigned_to_ids"]) {
            self.data.assigned_to = [];

            let assignee_ids = response["assigned_to_ids"].split(",");
            let assignee_names = response["Assigned To"].split(",");

            for (let i = 0; i < assignee_ids.length; i++) {
              self.data.assigned_to.push({
                id: assignee_ids[i].trim(),
                label: assignee_names[i].trim()
              });
            }
          }
          self.data.recurring_type = {
            id: response["Recurring"],
            label: response["Recurring"]
          };

          self.data.id = self.id;

          self.data.sendnotification = response["sendnotification"] == "1";

          self.data.task_name = response["Task Name"];

          self.data.action = response["Action"];

          self.actionObject = self.$helpers.getJSONObject(response["Action"]);

          self.data.activity_reminder = self.actionObject !== null;

          self.data.description = response["Description"];

          self.data.date_start = response["Start Date"];
          self.data.due_date = response["Due Date"];
        })
        .catch(error => {
          console.log(error);

          self.$form.makeToastError(error.message);

          self.isLoading = false;
        });
    },
    updateControlsState: function() {
      //allow editing just in create mode

      let _readonly = true;

      if (this.mode === this.FORM_MODE.CREATE) {
        _readonly = false;
      }

      for (var prop in this.controls) {
        if (Object.prototype.hasOwnProperty.call(this.controls, prop)) {
          this.controls[prop].readonly = _readonly;
        }
      }
    },
    onProjectLinkClicked(e) {
      this.$refs["project-preview-modal"].show(e);
    },
    onDataHistoryLoad(count) {
      if (count > 0)
        this.controls.tabs.dataHistory.title = `Data History (${count})`;
    }
  },
  watch: {}
};
</script>

<style scoped>
.vue-daterange-picker {
  display: block !important;
}

.vue__time-picker input.display-time {
  border: 0px solid #d2d2d2;
  width: 7em;
  height: 2.2em;
  margin: -0.3em -0.5em;
}

.ck.ck-editor__editable_inline {
  border: 1px solid #e8e8e8;
  min-height: 200px !important;
}

.ck.ck-editor__editable_inline.ck-focused {
  background-color: #ffffed;
}

.btn-outline-dark {
  border-color: #ccc;
}

.daily-content {
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
}
</style>
