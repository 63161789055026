var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "opacity": 0.5,
      "spinner-variant": "secondary",
      "rounded": "sm"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "2",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.updateViewMode('Day');
      }
    }
  }, [_vm._v(" Day ")]), _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.updateViewMode('Week');
      }
    }
  }, [_vm._v(" Week ")]), _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.updateViewMode('Month');
      }
    }
  }, [_vm._v(" Month ")]), _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.updateViewMode('Year');
      }
    }
  }, [_vm._v(" Year ")])], 1)], 1), _c('b-col', {
    attrs: {
      "lg": "2",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-input', {
    ref: "text-input-search",
    attrs: {
      "placeholder": "type to search..."
    },
    on: {
      "keyup": function keyup($event) {
        return _vm.onSearchKeyUp();
      }
    }
  })], 1), _c('b-col', {
    attrs: {
      "sm": "6",
      "md": "6",
      "lg": "3"
    }
  }, [_c('multiselect', {
    attrs: {
      "options": _vm.groupBy.options,
      "multiple": false,
      "show-labels": false,
      "allow-empty": false,
      "placeholder": "Select grouping mode"
    },
    on: {
      "input": _vm.onGroupByChange
    },
    model: {
      value: _vm.groupBy.selected,
      callback: function callback($$v) {
        _vm.$set(_vm.groupBy, "selected", $$v);
      },
      expression: "groupBy.selected"
    }
  })], 1)], 1), _c('div', [_c('perfect-scrollbar', [_c('div', {
    staticStyle: {
      "height": "1000px"
    }
  }, [false ? _c('gantt') : _vm._e(), _c('svg', {
    ref: "gantt"
  })], 1)])], 1)], 1), _c('b-modal', {
    ref: "preview-project",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "lg",
      "title": _vm.previewProject.title
    }
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('strong', [_vm._v("Project Name:")]), _vm._v(" " + _vm._s(_vm.previewProject.projectName) + " ")]), _c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('strong', [_vm._v("Progress:")]), _vm._v(" " + _vm._s(_vm.previewProject.progress) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('strong', [_vm._v("Start Date:")]), _vm._v(" " + _vm._s(_vm.previewProject.startDate) + " ")]), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('strong', [_vm._v("Assigned to:")]), _vm._v(" " + _vm._s(_vm.previewProject.assignedTo) + " ")])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('strong', [_vm._v("Finish Date:")]), _vm._v(" " + _vm._s(_vm.previewProject.finishDate) + " ")])], 1), _c('b-row', [_c('b-col')], 1), _c('b-row', [_c('b-col', [_c('strong', [_vm._v("Description:")]), _c('b-card-body', [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.previewProject.description)
    }
  })])], 1)], 1)], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('div', {
    staticClass: "btn-group"
  }, [_c('b-button', {
    attrs: {
      "variant": "outline-secondary",
      "title": "Add task"
    },
    on: {
      "click": function click($event) {
        return _vm.addTask();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  }), _vm._v("Add task ")], 1), _c('b-button', {
    attrs: {
      "variant": "outline-info",
      "title": "Edit project"
    },
    on: {
      "click": function click($event) {
        return _vm.editProject(_vm.previewProject.ID);
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "pencil-alt"
    }
  }), _vm._v("Edit ")], 1), _c('b-button', {
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.previewProjectHide();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v("Close ")], 1)], 1)])], 1), _c('b-modal', {
    ref: "preview-task",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "lg",
      "title": _vm.previewTask.title
    }
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.previewTask.controls.task_name.id,
      "value": _vm.previewTask.controls.task_name.value,
      "label": _vm.previewTask.controls.task_name.label,
      "readonly": _vm.previewTask.controls.task_name.readonly,
      "mode": _vm.FORMCONTROLMODE.VIEW
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.previewTask.controls.project_name.id,
      "value": _vm.previewTask.controls.project_name.value,
      "label": _vm.previewTask.controls.project_name.label,
      "readonly": _vm.previewTask.controls.project_name.readonly,
      "mode": _vm.FORMCONTROLMODE.VIEW
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.previewTask.controls.creator.id,
      "value": _vm.previewTask.controls.creator.value,
      "label": _vm.previewTask.controls.creator.label,
      "readonly": _vm.previewTask.controls.creator.readonly,
      "mode": _vm.FORMCONTROLMODE.VIEW
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.previewTask.controls.assigned_to.id,
      "value": _vm.previewTask.controls.assigned_to.value,
      "label": _vm.previewTask.controls.assigned_to.label,
      "readonly": _vm.previewTask.controls.assigned_to.readonly,
      "mode": _vm.FORMCONTROLMODE.VIEW
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.previewTask.controls.date_start.id,
      "value-single": _vm.previewTask.controls.date_start.value,
      "label": _vm.previewTask.controls.date_start.label,
      "readonly": _vm.previewTask.controls.date_start.readonly,
      "mode": _vm.FORMCONTROLMODE.VIEW
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('inline-date-picker', {
    attrs: {
      "id": _vm.previewTask.controls.due_date.id,
      "value-single": _vm.previewTask.controls.due_date.value,
      "label": _vm.previewTask.controls.due_date.label,
      "readonly": _vm.previewTask.controls.due_date.readonly,
      "mode": _vm.FORMCONTROLMODE.VIEW
    },
    on: {
      "changed": _vm.updateDateField
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.previewTask.controls.time_start.id,
      "value": _vm.previewTask.controls.time_start.value,
      "label": _vm.previewTask.controls.time_start.label,
      "readonly": _vm.previewTask.controls.time_start.readonly,
      "mode": _vm.FORMCONTROLMODE.VIEW
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('inline-input', {
    attrs: {
      "id": _vm.previewTask.controls.time_end.id,
      "value": _vm.previewTask.controls.time_end.value,
      "label": _vm.previewTask.controls.time_end.label,
      "readonly": _vm.previewTask.controls.time_end.readonly,
      "mode": _vm.FORMCONTROLMODE.VIEW
    }
  })], 1)], 1), _c('b-row'), _c('b-row', [_c('b-col', [_c('strong', [_vm._v("Description:")]), _c('b-card-body', [_c('div', {
    staticClass: "preview-report",
    domProps: {
      "innerHTML": _vm._s(_vm.previewTask.controls.description.value)
    }
  })])], 1)], 1), _c('b-row', [_c('b-col')], 1)], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('div', {
    staticClass: "btn-group"
  }, [!(_vm.previewTask.task && _vm.previewTask.task.Recurring) ? _c('b-button', {
    attrs: {
      "variant": "outline-success",
      "title": "Complete task"
    },
    on: {
      "click": function click($event) {
        return _vm.completeTask(_vm.previewTask.ID);
      }
    }
  }, [_c('clip-loader', {
    attrs: {
      "size": 13,
      "color": "#36D7B7",
      "loading": _vm.previewTask.task && _vm.previewTask.task.isCompleting === true
    }
  }), !(_vm.previewTask.task && _vm.previewTask.task.isCompleting === true) ? _c('font-awesome-icon', {
    attrs: {
      "icon": "check-circle"
    }
  }) : _vm._e(), _vm._v("Complete ")], 1) : _vm._e(), _vm.previewTask.task && _vm.previewTask.task.Recurring ? _c('b-button', {
    attrs: {
      "variant": "outline-secondary",
      "title": "Postpone task"
    },
    on: {
      "click": function click($event) {
        return _vm.postponeTask(_vm.previewTask.ID);
      }
    }
  }, [_c('clip-loader', {
    attrs: {
      "size": 13,
      "color": "#36D7B7",
      "loading": _vm.previewTask.task.isPostponing === true
    }
  }), !(_vm.previewTask.task.isPostponing === true) ? _c('font-awesome-icon', {
    attrs: {
      "icon": "clock"
    }
  }) : _vm._e(), _vm._v("Postpone ")], 1) : _vm._e(), _c('b-button', {
    attrs: {
      "variant": "outline-info",
      "title": "Edit task"
    },
    on: {
      "click": function click($event) {
        return _vm.editTask(_vm.previewTask.ID);
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "pencil-alt"
    }
  }), _vm._v("Edit ")], 1), _c('b-button', {
    attrs: {
      "variant": "outline-info",
      "title": "Clone task"
    },
    on: {
      "click": function click($event) {
        return _vm.cloneTask(_vm.previewTask.ID);
      }
    }
  }, [_c('clip-loader', {
    attrs: {
      "size": 13,
      "color": "#36D7B7",
      "loading": _vm.previewTask.task && _vm.previewTask.task.isCloning === true
    }
  }), !(_vm.previewTask.task && _vm.previewTask.task.isCloning === true) ? _c('font-awesome-icon', {
    attrs: {
      "icon": "clone"
    }
  }) : _vm._e(), _vm._v("Clone ")], 1), _c('b-button', {
    attrs: {
      "variant": "outline-warning",
      "title": "Email task"
    },
    on: {
      "click": function click($event) {
        return _vm.emailTask(_vm.previewTask.ID);
      }
    }
  }, [_c('clip-loader', {
    attrs: {
      "size": 13,
      "color": "#36D7B7",
      "loading": _vm.previewTask.task && _vm.previewTask.task.isEmailing === true
    }
  }), !(_vm.previewTask.task && _vm.previewTask.task.isEmailing === true) ? _c('font-awesome-icon', {
    attrs: {
      "icon": "envelope"
    }
  }) : _vm._e(), _vm._v("Email ")], 1), _c('b-button', {
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.previewTaskHide();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v("Close ")], 1)], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }