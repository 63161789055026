<template>
  <div class="animated fadeIn">
    <b-container fluid>
      <b-overlay
        :show="isLoading"
        :opacity="0.5"
        spinner-variant="secondary"
        rounded="sm"
      >
        <b-row>
          <b-col lg="2" md="12" sm="12">
            <b-button-group>
              <b-button
                variant="outline-primary"
                @click="updateViewMode('Day')"
              >
                Day
              </b-button>
              <b-button
                variant="outline-primary"
                @click="updateViewMode('Week')"
              >
                Week
              </b-button>
              <b-button
                variant="outline-primary"
                @click="updateViewMode('Month')"
              >
                Month
              </b-button>
              <b-button
                variant="outline-primary"
                @click="updateViewMode('Year')"
              >
                Year
              </b-button>
            </b-button-group>
          </b-col>
          <b-col lg="2" md="12" sm="12">
            <b-input
              :ref="`text-input-search`"
              placeholder="type to search..."
              @keyup="onSearchKeyUp()"
            />
          </b-col>
          <b-col sm="6" md="6" lg="3">
            <multiselect
              v-model="groupBy.selected"
              :options="groupBy.options"
              @input="onGroupByChange"
              :multiple="false"
              :show-labels="false"
              :allow-empty="false"
              placeholder="Select grouping mode"
            />
          </b-col>
        </b-row>

        <div>
          <perfect-scrollbar>
            <div style="height: 1000px;">
              <gantt v-if="false"></gantt>
              <svg ref="gantt" />
            </div>
          </perfect-scrollbar>
        </div>
      </b-overlay>

      <b-modal
        ref="preview-project"
        centered
        hide-footer
        size="lg"
        :title="previewProject.title"
      >
        <b-card>
          <b-card-body>
            <b-row>
              <b-col lg="4">
                <strong>Project Name:</strong>
                {{ previewProject.projectName }}
              </b-col>
              <b-col lg="4">
                <strong>Progress:</strong>
                {{ previewProject.progress }}
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <strong>Start Date:</strong>
                {{ previewProject.startDate }}
              </b-col>
              <b-col lg="8">
                <strong>Assigned to:</strong>
                {{ previewProject.assignedTo }}
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <strong>Finish Date:</strong>
                {{ previewProject.finishDate }}
              </b-col>
            </b-row>
            <b-row>
              <b-col />
            </b-row>
            <b-row>
              <b-col>
                <strong>Description:</strong>
                <b-card-body>
                  <div v-html="previewProject.description" />
                </b-card-body>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <hr />
        <div class="form-row d-flex justify-content-end">
          <div class="btn-group">
            <b-button
              variant="outline-secondary"
              @click="addTask()"
              title="Add task"
            >
              <font-awesome-icon icon="plus" />Add task
            </b-button>
            <b-button
              variant="outline-info"
              @click="editProject(previewProject.ID)"
              title="Edit project"
            >
              <font-awesome-icon icon="pencil-alt" />Edit
            </b-button>
            <b-button variant="outline-dark" @click="previewProjectHide()">
              <font-awesome-icon icon="times" />Close
            </b-button>
          </div>
        </div>
      </b-modal>

      <b-modal
        ref="preview-task"
        centered
        hide-footer
        size="lg"
        :title="previewTask.title"
      >
        <b-card>
          <b-card-body>
            <b-row>
              <b-col lg="3">
                <inline-input
                  :id="previewTask.controls.task_name.id"
                  :value="previewTask.controls.task_name.value"
                  :label="previewTask.controls.task_name.label"
                  :readonly="previewTask.controls.task_name.readonly"
                  :mode="FORMCONTROLMODE.VIEW"
                />
              </b-col>
              <b-col lg="3">
                <inline-input
                  :id="previewTask.controls.project_name.id"
                  :value="previewTask.controls.project_name.value"
                  :label="previewTask.controls.project_name.label"
                  :readonly="previewTask.controls.project_name.readonly"
                  :mode="FORMCONTROLMODE.VIEW"
                />
              </b-col>

              <b-col lg="3">
                <inline-input
                  :id="previewTask.controls.creator.id"
                  :value="previewTask.controls.creator.value"
                  :label="previewTask.controls.creator.label"
                  :readonly="previewTask.controls.creator.readonly"
                  :mode="FORMCONTROLMODE.VIEW"
                />
              </b-col>
              <b-col lg="3">
                <inline-input
                  :id="previewTask.controls.assigned_to.id"
                  :value="previewTask.controls.assigned_to.value"
                  :label="previewTask.controls.assigned_to.label"
                  :readonly="previewTask.controls.assigned_to.readonly"
                  :mode="FORMCONTROLMODE.VIEW"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3">
                <inline-date-picker
                  :id="previewTask.controls.date_start.id"
                  :value-single="previewTask.controls.date_start.value"
                  :label="previewTask.controls.date_start.label"
                  :readonly="previewTask.controls.date_start.readonly"
                  :mode="FORMCONTROLMODE.VIEW"
                  @changed="updateDateField"
                />
              </b-col>
              <b-col lg="3">
                <inline-date-picker
                  :id="previewTask.controls.due_date.id"
                  :value-single="previewTask.controls.due_date.value"
                  :label="previewTask.controls.due_date.label"
                  :readonly="previewTask.controls.due_date.readonly"
                  :mode="FORMCONTROLMODE.VIEW"
                  @changed="updateDateField"
                />
              </b-col>
              <b-col lg="3">
                <inline-input
                  :id="previewTask.controls.time_start.id"
                  :value="previewTask.controls.time_start.value"
                  :label="previewTask.controls.time_start.label"
                  :readonly="previewTask.controls.time_start.readonly"
                  :mode="FORMCONTROLMODE.VIEW"
                />
              </b-col>
              <b-col lg="3">
                <inline-input
                  :id="previewTask.controls.time_end.id"
                  :value="previewTask.controls.time_end.value"
                  :label="previewTask.controls.time_end.label"
                  :readonly="previewTask.controls.time_end.readonly"
                  :mode="FORMCONTROLMODE.VIEW"
                />
              </b-col>
            </b-row>
            <b-row />
            <b-row>
              <b-col>
                <strong>Description:</strong>
                <b-card-body>
                  <div
                    class="preview-report"
                    v-html="previewTask.controls.description.value"
                  />
                </b-card-body>
              </b-col>
            </b-row>
            <b-row>
              <b-col />
            </b-row>
          </b-card-body>
        </b-card>
        <hr />
        <div class="form-row d-flex justify-content-end">
          <div class="btn-group">
            <b-button
              v-if="!(previewTask.task && previewTask.task.Recurring)"
              variant="outline-success"
              title="Complete task"
              @click="completeTask(previewTask.ID)"
            >
              <clip-loader
                class
                :size="13"
                color="#36D7B7"
                :loading="
                  previewTask.task && previewTask.task.isCompleting === true
                "
              />
              <font-awesome-icon
                icon="check-circle"
                v-if="
                  !(previewTask.task && previewTask.task.isCompleting === true)
                "
              />Complete
            </b-button>
            <b-button
              v-if="previewTask.task && previewTask.task.Recurring"
              variant="outline-secondary"
              title="Postpone task"
              @click="postponeTask(previewTask.ID)"
            >
              <clip-loader
                class
                :size="13"
                color="#36D7B7"
                :loading="previewTask.task.isPostponing === true"
              />
              <font-awesome-icon
                icon="clock"
                v-if="!(previewTask.task.isPostponing === true)"
              />Postpone
            </b-button>

            <b-button
              variant="outline-info"
              @click="editTask(previewTask.ID)"
              title="Edit task"
            >
              <font-awesome-icon icon="pencil-alt" />Edit
            </b-button>
            <b-button
              variant="outline-info"
              @click="cloneTask(previewTask.ID)"
              title="Clone task"
            >
              <clip-loader
                class
                :size="13"
                color="#36D7B7"
                :loading="
                  previewTask.task && previewTask.task.isCloning === true
                "
              />
              <font-awesome-icon
                icon="clone"
                v-if="
                  !(previewTask.task && previewTask.task.isCloning === true)
                "
              />Clone
            </b-button>

            <b-button
              variant="outline-warning"
              @click="emailTask(previewTask.ID)"
              title="Email task"
            >
              <clip-loader
                class
                :size="13"
                color="#36D7B7"
                :loading="
                  previewTask.task && previewTask.task.isEmailing === true
                "
              />
              <font-awesome-icon
                icon="envelope"
                v-if="
                  !(previewTask.task && previewTask.task.isEmailing === true)
                "
              />Email
            </b-button>

            <b-button variant="outline-dark" @click="previewTaskHide()">
              <font-awesome-icon icon="times" />Close
            </b-button>
          </div>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import moment from "moment";

import InlineDatePicker from "@/components/InlineDatePicker";
import InlineInput from "@/components/InlineInput";

import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";

import Gantt from "@/components/FrappeGanttCustom";

import {
  completeTask,
  cloneTask,
  emailTask,
  postponeTask,
  updateTask
} from "./helpers.js";

import { FORMCONTROLMODE } from "@/shared/constants";

export default {
  name: "Gantt",
  props: {
    projectId: {
      type: [String, Number],
      default: undefined
    }
  },
  components: {
    Gantt,
    InlineDatePicker,
    InlineInput,
    Multiselect
  },
  data: function() {
    return {
      FORMCONTROLMODE: FORMCONTROLMODE,
      isLoading: false,
      rawDataProjects: [],
      rawData: [],
      dataTable: {
        dataSet: []
      },
      debugEventLog: [],
      viewMode: "Month",
      users: [],
      tasks: [],
      groupBy: {
        selected: "Projects",
        options: ["Projects", "Users"]
      },
      previewProject: {
        id: "",
        projectName: "",
        progress: 0,
        assigned_to: "",
        startDate: "",
        finishDate: "",
        description: ""
      },
      previewTask: {
        task: undefined,
        ID: "",
        controls: {
          task_name: {
            id: "input:task_name",
            label: "Task Name",
            value: "",
            changed: false,
            readonly: true
          },
          project_name: {
            id: "input:project_name",
            label: "Project",
            value: "",
            changed: false,
            readonly: true
          },
          date_start: {
            id: "datepicker:date_start",
            label: "Start Date",
            changed: false,
            value: "",
            readonly: false
          },
          due_date: {
            id: "datepicker:due_date",
            label: "Due Date",
            value: "",
            changed: false,
            readonly: false
          },
          creator: {
            id: "input:creator",
            label: "Assignor",
            value: "",
            changed: false,
            readonly: true
          },

          assigned_to: {
            id: "input:assigned_to",
            label: "Assigned To",
            value: "",
            changed: false,
            readonly: true
          },
          description: {
            id: "input:description",
            label: "Description",
            value: "",
            changed: false,
            readonly: true
          },
          time_start: {
            id: "input:time_start",
            label: "Time start",
            value: "",
            changed: false,
            readonly: true
          },
          time_end: {
            id: "input:time_end",
            label: "Time end",
            value: "",
            changed: false,
            readonly: true
          }
        }
      }
    };
  },
  computed: {},
  mounted() {
    console.log("1.gantt.vue.gantt.mounted", this.projectId);
    if (this.projectId) this.getData();
  },
  methods: {
    onGroupByChange() {
      this.updateTasks(this.dataTable.dataSet);
    },
    onSearchKeyUp() {
      let text = this.$refs["text-input-search"].vModelValue;

      if (text.trim() === "") {
        this.dataTable.dataSet.forEach(i => (i.visible = true));
      } else {
        this.dataTable.dataSet.forEach(
          i =>
            (i.visible = i["Task Name"]
              .toLowerCase()
              .includes(text.toLowerCase()))
        );
      }

      this.updateTasks(this.dataTable.dataSet.filter(t => t.visible));
      //this.$forceUpdate()
    },

    updateDateField(e) {
      this.updateTaskField(e.id, e.valueSingleAsString);
    },

    updateTaskField(_field, _value) {
      let self = this;
      let params = {};

      params["id"] = this.previewTask.ID;
      params[_field] = _value;

      //update local data values - it can be used in Edit action
      //payload.self.data[payload.field] = payload.value;

      this.$api
        .put(`tasks/${this.previewTask.ID}`, params)
        .then(response => {
          self.$form.makeToastInfo(`${_field}: ${response.message}`);

          //payload.self.controls[payload.field].changed = false;

          let task = this.dataTable.dataSet.find(
            i => i.ID === this.previewTask.ID
          );
          task[self.previewTask.controls[_field].label] = _value;
          self.updateTasks(this.dataTable.dataSet.filter(t => t.visible));
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(`${_field}: ${response.message}`);
        });
    },

    addTask: function() {
      this.$router.push({
        name: "Task submission",
        params: {
          action: "create"
        }
      });

      return;
    },
    setupGanttChart() {
      console.log("2.gantt.vue.setupGanttChart");
      let that = this;

      this.gantt = new Gantt(that.$refs.gantt, that.tasks, {
        header_height: 50,
        column_width: 30,
        step: 24,
        view_modes: ["Day", "Week", "Month", "Year"],
        bar_height: 30,
        bar_corner_radius: 3,
        arrow_curve: 5,
        padding: 18,
        view_mode: "Day",
        date_format: "YYYY-MM-DD",
        custom_class: "gantt-bar",
        /*
                        custom_popup_html: function (task) {
                            return `${task.end}`
                        },
                        */
        on_click: task => {
          that.previewItem(task);
        },
        on_date_changing: function() {
          //console.log(end)
          //let t = that.tasks.find(i => i.id === task.id);
          //t.name = end
          //if (that.gantt) that.gantt.refresh(that.tasks);
        },
        on_date_change: function(task, start, end) {
          if (task.isProject) return false;

          var duration = moment.duration(moment(end).diff(start));
          var hours = duration.asHours();

          //draw 1 day tasks in the 1 date
          if (hours < 24) end = start;

          let payload = {
            id: task.id,
            start: moment(start)
              .local()
              .format("YYYY-MM-DD"),
            end: moment(end)
              .local()
              .format("YYYY-MM-DD"),
            this: that
          };

          //updateTask(payload).then(that.drawDataTable)

          updateTask(payload);
        },
        on_progress_change: () => {},
        on_view_change: () => {}
      });

      this.updateViewMode("Month");
    },

    previewProjectShow() {
      this.$refs["preview-project"].show();
    },
    previewProjectHide() {
      this.$refs["preview-project"].hide();
    },
    previewTaskHide: function() {
      this.$refs["preview-task"].hide();
    },
    completeTask(id) {
      completeTask(id, this);
    },
    postponeTask(id) {
      postponeTask(id, this);
    },
    updateTask(id) {
      updateTask(id, this);
    },
    cloneTask(id) {
      cloneTask(id, this, true);
    },
    emailTask(id) {
      emailTask(id, this, false);
    },
    editTask: function(id) {
      this.$router.push({
        name: "Task submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    editProject(id) {
      this.$router.push({
        name: "Project submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    previewItem(item) {
      if (item.isProject) {
        let project = this.rawDataProjects.find(i => i.ID === item.id);

        if (!project) return;
        this.previewProject.title = `Project #${project.ID}`;
        this.previewProject.id = project.ID;
        this.previewProject.projectName = project["Project Name"];
        this.previewProject.progress =
          parseFloat(project["Progress"]).toFixed(2) + " %";
        this.previewProject.assignedTo = project["Assigned To"];
        this.previewProject.startDate = project["Start Date"];
        this.previewProject.finishDate = project["Finish Date"];
        this.previewProject.description = project["Description"];
        this.previewProjectShow();
        return;
      }

      let task = this.dataTable.dataSet.find(i => i.ID === item.id);

      if (!task) return;

      this.previewTask.task = task;
      this.previewTask.ID = task.ID;
      this.previewTask.title = `Task #${task.ID}`;
      this.previewTask.controls.task_name.value = task["Task Name"];
      this.previewTask.controls.project_name.value = task["Project Name"];
      this.previewTask.controls.creator.value = task["Creator"];
      this.previewTask.controls.assigned_to.value = task["Assigned To"];
      this.previewTask.controls.date_start.value = task["Start Date"];
      this.previewTask.controls.due_date.value = task["Due Date"];
      this.previewTask.controls.time_start.value = task["Start Time"];
      this.previewTask.controls.time_end.value = task["End Time"];
      this.previewTask.controls.description.value = task["Description"];

      this.$refs["preview-task"].show();
    },

    updateViewMode(mode) {
      this.viewMode = mode;

      if (this.gantt) this.gantt.change_view_mode(this.viewMode);

      //this.gantt.set_scroll_position();
    },
    getTaskColorByStatus(task) {
      let cc = "";
      if (["Not Started", "Planned"].includes(task["Status"])) cc = "gray-task";
      if (["In Progress"].includes(task["Status"])) cc = "orange-task";
      if (["Pending Input"].includes(task["Status"])) cc = "red-task";

      if (moment(moment().format("YYYY-MM-DD")).isAfter(task["Due Date"])) {
        cc = "red-task";
      }
      return cc;
    },
    groupTasksByProjects(dataSet) {
      console.log(
        "3.gantt.vue.groupTasksByProjects.dataSet.length",
        dataSet.length
      );

      let tasks = [];
      //debugger
      console.log(
        "3.1.gantt.vue.groupTasksByProjects.rawDataProjects.length",
        this.rawDataProjects.length
      );
      //debugger
      this.rawDataProjects.sort((a, b) =>
        a["Project Name"].localeCompare(b["Project Name"])
      );

      //add projects and child tasks
      this.rawDataProjects.forEach(project => {
        //debugger
        if (parseFloat(project["tasks_total"]) > 0) {
          let parent = {
            id: project["ID"],
            name: project["Project Name"],
            start: project["Start Date"],
            end: project["Finish Date"],
            assigned_to: project["Assigned To"],
            progress: parseFloat(project["Progress"]),
            isProject: true
          };

          let childs = dataSet
            .filter(
              task =>
                task["Start Date"] > "2015-01-01" &&
                task["Project ID"] == project["ID"]
            )
            .map(task => ({
              id: task["ID"],
              name: task["Task Name"],
              start: task["Start Date"],
              end: task["Due Date"],
              progress: 100,
              project_id: task["Project ID"],
              dependencies: task["Project ID"],
              custom_class: this.getTaskColorByStatus(task)
            }));
          //debugger
          if (childs.length > 0) {
            let minStartDate = childs.reduce(
              (min, p) =>
                moment(p.start).isBefore(moment(min)) ? p.start : min,
              childs[0].start
            );
            let maxEndDate = childs.reduce(
              (max, p) => (moment(p.end).isAfter(moment(max)) ? p.end : max),
              childs[0].end
            );

            parent.start = minStartDate;
            parent.end = maxEndDate;

            childs.sort((a, b) => a["name"].localeCompare(b["name"]));

            tasks.push(parent);

            tasks = [...tasks, ...childs];
          }
        }
      });

      console.log(
        "3.2.gantt.vue.groupTasksByProjects.tasks.length",
        tasks.length
      );
      //debugger
      //add tasks without parent project
      let noparent = dataSet
        .filter(
          task => task["Start Date"] > "2015-01-01" && task["Project ID"] === ""
        )
        .map(task => ({
          id: task["ID"],
          name: task["Task Name"],
          start: task["Start Date"],
          end: task["Due Date"],
          progress: 100,
          dependencies: task["Project ID"],
          custom_class: this.getTaskColorByStatus(task)
        }));

      noparent.sort((a, b) => a["name"].localeCompare(b["name"]));

      tasks = [...tasks, ...noparent];

      console.log(
        "3.3.gantt.vue.groupTasksByProjects.tasks.length",
        tasks.length
      );
      return tasks;
    },
    groupTasksByUsers(dataSet) {
      console.log("4.gantt.vue.groupTasksByUsers");

      dataSet = Array.from(new Set(dataSet));

      let tasks = [];

      //debugger
      this.users.sort((a, b) => a.full_name.localeCompare(b.full_name));

      //add users and child tasks
      this.users.forEach(user => {
        if (
          dataSet.find(task =>
            task["assigned_to_ids"].split(",").includes(user.id)
          )
        ) {
          let parent = {
            id: user.id,
            name: user.full_name,
            start: undefined,
            end: undefined,
            //assigned_to: project["Assigned To"],
            //progress: parseFloat(project["Progress"]),
            isProject: true
          };

          let childs = dataSet
            .filter(
              task =>
                task["Start Date"] > "2015-01-01" &&
                task["assigned_to_ids"].split(",").includes(user.id)
            )
            .map(task => ({
              id: task["ID"],
              name: task["Task Name"],
              start: task["Start Date"],
              end: task["Due Date"],
              progress: 100,
              project_id: task["Project ID"],
              dependencies: user.id,
              custom_class: this.getTaskColorByStatus(task)
            }));

          if (childs.length > 0) {
            let minStartDate = childs.reduce(
              (min, p) =>
                moment(p.start).isBefore(moment(min)) ? p.start : min,
              childs[0].start
            );
            let maxEndDate = childs.reduce(
              (max, p) => (moment(p.end).isAfter(moment(max)) ? p.end : max),
              childs[0].end
            );

            parent.start = minStartDate;
            parent.end = maxEndDate;

            childs.sort((a, b) => a["name"].localeCompare(b["name"]));

            tasks.push(parent);

            tasks = [...tasks, ...childs];
          }
        }
      });

      return tasks;
    },
    prepareTasksArray(dataSet) {
      if (!this.groupBy.selected) this.groupBy.selected = "Projects";
      console.log(
        "5.gantt.vue.prepareTasksArray.this.groupBy.selected ",
        this.groupBy.selected,
        "dataset.length",
        dataSet.length
      );
      if (this.groupBy.selected === "Projects")
        return this.groupTasksByProjects(dataSet);

      if (this.groupBy.selected === "Users")
        return this.groupTasksByUsers(dataSet);
    },
    updateTasks(dataSet) {
      console.log("6.gantt.vue.updateTasks", dataSet);
      if (dataSet.length > 500) {
        this.loading(false);

        this.$form.msgBoxOk(
          `Too many tasks (${dataSet.length}) for display! Select another filter please.`
        );
        return;
      }
      console.log("5.1.gantt.vue.updateTasks", dataSet.length);
      this.tasks = this.prepareTasksArray(dataSet);

      this.loading(false);
      console.log("5.2.gantt.vue.updateTasks", this.tasks.length);
      if (!this.tasks.length) {
        this.$form.msgBoxOk("No data available!");
        return;
      }
      console.log("5.3.gantt.vue.updateTasks", this.tasks.length);
      if (!this.gantt) {
        this.setupGanttChart();
      }
      console.log("7.gantt.vue.updateTasks", this.gantt, this.tasks.length);
      this.gantt.refresh(this.tasks);
    },
    loading(status) {
      console.log("8.gantt.vue.loading", status);
      this.isLoading = status;
    },
    async getData() {
      console.log("9.gantt.vue.getData");
      await this.drawDataTable();
    },
    async drawDataTable() {
      console.log("10.gantt.vue.drawDataTable");

      this.loading(true);

      this.users = await this.$api.get("dictionaries/users");

      this.rawDataProjects = await this.$api.get("projects");

      console.log("11.gantt.vue.rawDataProjects", this.rawDataProjects);

      this.rawData = await this.$api.get("tasks");

      console.log("12.gantt.vue.rawData", this.rawData);

      this.loading(false);

      this.isLoaded = true;

      //this.dataTable.dataSet = this.rawData;

      //create array of selected values for multiselect input
      this.rawData.forEach(row => {
        let values = [];

        if (row["Assigned To"].length > 0) {
          let ids = row.assigned_to_ids.split(",");
          let names = row["Assigned To"].split(",");

          for (let i = 0; i < ids.length; i++) {
            values.push({
              id: ids[i].trim(),
              label: names[i].trim()
            });
          }
        }

        row.assignedTo = values;
        row.visible = true;
      });

      //this.updateTasks(this.dataTable.dataSet);

      this.$emit("loaded");

      return true;
    }
  },
  watch: {
    "dataTable.dataSet"(newVal) {
      //debugger

      console.log("13.gantt.vue.watch.dataTable.dataSet", newVal.length);
      this.updateTasks(newVal);
    },
    viewMode() {
      // this.updateViewMode();
    },

    tasks() {
      //  this.updateTasks();
    }
  }
};
</script>

<style scoped>
.bar-label {
  fill: black !important;
}

.gantt .bar-wrapper.active .bar-progress {
  fill: #4dbd7491;
  stroke-width: 1;
  stroke: rgb(0, 0, 0);
}

.gantt .bar-wrapper:hover .bar-progress {
  fill: #4dbd74;
  stroke-width: 1;
  stroke: rgb(0, 0, 0);
}

.gantt .bar {
  fill: #4dbd7491;
}

.gantt .bar-progress {
  fill: #4dbd74;
}

.orange-task g rect {
  fill: #ffc107 !important;
}

.gray-task g rect {
  fill: #c8ced3 !important;
}

.red-task g rect {
  fill: #f86c6b !important;
}

.ps {
  height: 800px;
}

.gantt-container {
  /*width: 1000px;*/
  overflow: inherit;
}

.gantt .bar-label {
  font-size: 16px;
  font-weight: normal;
}
</style>
