<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`custom_table_contact_history`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    />
  </div>
</template>

<script>
import TableCustom from "@/components/TableCustom";

export default {
  props: {
    contactId: {
      type: String,
      default: ""
    }
  },
  name: "ContactHistoryTable",
  components: {
    TableCustom
  },
  data: function() {
    return {
      rawData: {},
      dataTable: {
        //loading: false,
        isLoading: false,
        dataSet: [],
        options: {
          columns: [
            "Account Name",
            "Status",
            "Modified By",
            "Modification Date"
          ],
          filterable: [
            "Account Name",
            "Status",
            "Modified By",
            "Modification Date"
          ],

          footerHeadings: false,
          perPage: 50,
          disablePerPageDropdown: false,
          showActions: false
        }
      }
    };
  },
  computed: {},
  mounted() {
    if (this.contactId) this.getData();
  },
  methods: {
    onFilter() {},

    async getData() {
      let self = this;

      this.dataTable.isLoading = true;

      //  this.dataTable.dataSet = [];

      let response = [];

      try {
        response = await this.$api.get(`contacts/${this.contactId}/history`);

        self.dataTable.isLoading = false;

        self.dataTable.dataSet = response;

        if (response.length === 0) return;

        self.$emit("loaded", self.dataTable.dataSet.length);
      } catch (error) {
        console.log(error);
        self.dataTable.isLoading = false;
        self.$form.msgBoxOk("Error occured");
      }
    }
  },
  watch: {}
};
</script>

<style scoped></style>
