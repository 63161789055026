var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    ref: "modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "size": "lg",
      "title": _vm.modal.title,
      "hide-footer": ""
    }
  }, [_c('b-container', {
    staticClass: "ma-0 pa-0 overflow-auto",
    staticStyle: {
      "height": "70vh"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('ProjectSubmissionForm', {
    attrs: {
      "id": _vm.modal.id,
      "action": "preview"
    }
  })], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.openInNewWindow(_vm.modal.id);
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "share"
    }
  }), _vm._v(" Open in new window ")], 1), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.close();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }