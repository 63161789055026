import { render, staticRenderFns } from "./ContactHistoryTable.vue?vue&type=template&id=502a4d26&scoped=true"
import script from "./ContactHistoryTable.vue?vue&type=script&lang=js"
export * from "./ContactHistoryTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "502a4d26",
  null
  
)

export default component.exports