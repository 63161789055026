var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('TableWrapper', {
    ref: _vm.model.name,
    attrs: {
      "model": _vm.model,
      "actions-mode": "event",
      "columns": _vm.tableColumns,
      "read-only": false,
      "disable-inline-editing": true,
      "filter-by-column": true
    },
    on: {
      "loaded": _vm.onTableLoad,
      "view": _vm.onViewItem
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }