<template>
  <div class="animated fadeIn">
    <TableWrapper
      :ref="model.name"
      :model="model"
      actions-mode="router"
      :columns="tableColumns"
      :read-only="false"
      :disable-inline-editing="true"
      :filter-by-column="true"
      @loaded="onTableLoad"
    >
    </TableWrapper>
  </div>
</template>

<script>
import models from "@/models";
import TableWrapper from "@/components/DataView/TableWrapper.vue";

export default {
  props: {
    accountId: {
      type: [Number, String],
      default: undefined
    },
    bottlingNumber: {
      type: [Number, String],
      default: undefined
    }
  },
  name: "PickListsTable",
  components: { TableWrapper },
  data: function() {
    return {
      model: models.warehouse.pickLists,
      tableColumns: [
        "ID",
        "Picklist Date",
        "PO Number",
        "PO Date",
        "Bottles",
        "Account Name",
        "Warehouse",
        "Status",
        "Invoice Number",
        "Created By"
      ]
    };
  },
  computed: {},
  created() {},

  mounted() {
    let payload = {
      account_id: this.accountId,
      bottling_number: this.bottlingNumber || -1
    };

    this.$refs[this.model.name].getData(payload);
  },
  methods: {
    onTableLoad(e) {
      this.$emit("loaded", e);
    }
  },
  watch: {}
};
</script>

<style></style>
