<template>
  <div class="animated fadeIn">
    <b-card>
      <b-tabs content-class="mt-3" justified>
        <b-tab title="Overview" active>
          <form>
            <h5>
              Contact details <b-spinner v-if="isLoading" small type="grow" />
            </h5>

            <hr />
            <b-row>
              <b-col lg="3" md="2" sm="2">
                <inline-input
                  :id="controls.salutation.id"
                  :value="data.salutation"
                  :label="controls.salutation.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="3" sm="3">
                <inline-input
                  :id="controls.firstname.id"
                  :value="data.firstname"
                  :label="controls.firstname.label"
                  :readonly="false"
                  :mode="mode"
                  :required="true"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="3" sm="3">
                <inline-input
                  :id="controls.lastname.id"
                  :value="data.lastname"
                  :label="controls.lastname.label"
                  :readonly="false"
                  :mode="mode"
                  :required="true"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="3" sm="3">
                <inline-input
                  :id="controls.created.id"
                  :value="data.created"
                  :label="controls.created.label"
                  :readonly="controls.created.readonly"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="3" sm="3">
                <inline-select
                  :id="controls.assigned_to.id"
                  :value="data.assigned_to"
                  :label="controls.assigned_to.label"
                  :allow-empty="false"
                  :readonly="false"
                  :options="controls.assigned_to.options"
                  :mode="mode"
                  :required="true"
                  @changed="updateSelect"
                />
              </b-col>
              <b-col lg="3" md="3" sm="3">
                <inline-input
                  :id="controls.title.id"
                  :value="data.title"
                  :label="controls.title.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="3" sm="3">
                <inline-input
                  :id="controls.department.id"
                  :value="data.department"
                  :label="controls.department.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="3" sm="3">
                <inline-input
                  :id="controls.modified.id"
                  :value="data.modified"
                  :label="controls.modified.label"
                  :readonly="controls.modified.readonly"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="3">
                <inline-select
                  :id="controls.account.id"
                  :width="30"
                  :value="data.account"
                  :label="controls.account.label"
                  :readonly="controls.account.readonly"
                  :options="controls.account.options"
                  :loading="controls.account.loading"
                  :allow-empty="true"
                  :multiple="true"
                  :mode="mode"
                  :link-mode="true"
                  :async="true"
                  @async-search="onAccountSearch"
                  @link-clicked="onAccountLinkClicked"
                  @changed="updateSelect"
                  :required="true"
                />
              </b-col>

              <b-col lg="3" md="3">
                <inline-select
                  :id="controls.emailoptout.id"
                  :value="data.emailoptout"
                  :label="controls.emailoptout.label"
                  :options="controls.emailoptout.options"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateSelect"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.email.id"
                  :value="data.email"
                  :label="controls.email.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.secondaryemail.id"
                  :value="data.secondaryemail"
                  :label="controls.secondaryemail.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="3">
                <inline-select
                  :id="controls.donotcall.id"
                  :value="data.donotcall"
                  :label="controls.donotcall.label"
                  :options="controls.donotcall.options"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateSelect"
                />
              </b-col>

              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.phone.id"
                  :value="data.phone"
                  :label="controls.phone.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.mobile.id"
                  :value="data.mobile"
                  :label="controls.mobile.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>

              <b-col lg="3" md="3">
                <inline-input
                  :id="controls.fax.id"
                  :value="data.fax"
                  :label="controls.fax.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <hr />

            <b-row>
              <b-col cols="2">
                <font-awesome-icon icon="chevron-down" />
                <h5
                  v-b-toggle.collapse-1
                  style="display: inline;cursor: pointer"
                >
                  Mailing address
                </h5>
              </b-col>
              <b-col cols="2" v-if="singleAccountSelected">
                <b-form-checkbox
                  id="cb-account-address"
                  v-model="sameAsAccountAddress"
                  name="cb-account-address"
                  :value="true"
                  :unchecked-value="false"
                  @change="onAccountAddressCheckChange"
                >
                  Same as account address
                </b-form-checkbox>
              </b-col>
            </b-row>
            <hr />
            <b-collapse id="collapse-1" class="mt-2">
              <b-row>
                <b-col lg="3" md="3">
                  <inline-input
                    :id="controls.street.id"
                    :value="data.street"
                    :label="controls.street.label"
                    :readonly="false"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
                <b-col lg="3" md="3">
                  <inline-select
                    :id="controls.country.id"
                    :value="data.country"
                    :label="controls.country.label"
                    :readonly="controls.country.readonly"
                    :options="controls.country.options"
                    :allow-empty="false"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="false"
                  />
                </b-col>

                <b-col lg="3" md="3">
                  <inline-select
                    :id="controls.state.id"
                    :value="data.state"
                    :label="controls.state.label"
                    :readonly="controls.state.readonly"
                    :options="controls.state.options"
                    :allow-empty="false"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="false"
                  />
                </b-col>
                <b-col lg="3" md="3">
                  <inline-select
                    :id="controls.city.id"
                    :value="data.city"
                    :label="controls.city.label"
                    :readonly="controls.city.readonly"
                    :options="controls.city.options"
                    :allow-empty="false"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="false"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="3">
                  <inline-input
                    :id="controls.zip.id"
                    :value="data.zip"
                    :label="controls.zip.label"
                    :readonly="false"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
                <b-col lg="3" md="3">
                  <inline-input
                    :id="controls.pobox.id"
                    :value="data.pobox"
                    :label="controls.pobox.label"
                    :readonly="false"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
              </b-row>
              <hr />
            </b-collapse>

            <b-row>
              <b-col cols="2">
                <font-awesome-icon icon="chevron-down" />
                <h5
                  v-b-toggle.collapse-2
                  style="display: inline;cursor: pointer"
                >
                  Other address
                </h5>
              </b-col>
            </b-row>

            <hr />

            <b-collapse id="collapse-2" class="mt-2">
              <b-row>
                <b-col lg="3" md="3">
                  <inline-input
                    :id="controls.otherstreet.id"
                    :value="data.otherstreet"
                    :label="controls.otherstreet.label"
                    :readonly="false"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
                <b-col lg="3" md="3">
                  <inline-select
                    :id="controls.othercountry.id"
                    :value="data.othercountry"
                    :label="controls.othercountry.label"
                    :readonly="controls.othercountry.readonly"
                    :options="controls.othercountry.options"
                    :allow-empty="false"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="false"
                  />
                </b-col>

                <b-col lg="3" md="3">
                  <inline-select
                    :id="controls.otherstate.id"
                    :value="data.otherstate"
                    :label="controls.otherstate.label"
                    :readonly="controls.otherstate.readonly"
                    :options="controls.otherstate.options"
                    :allow-empty="false"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="false"
                  />
                </b-col>
                <b-col lg="3" md="3">
                  <inline-select
                    :id="controls.othercity.id"
                    :value="data.othercity"
                    :label="controls.othercity.label"
                    :readonly="controls.othercity.readonly"
                    :options="controls.othercity.options"
                    :allow-empty="false"
                    :mode="mode"
                    @changed="updateSelect"
                    :required="false"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" md="3">
                  <inline-input
                    :id="controls.otherzip.id"
                    :value="data.otherzip"
                    :label="controls.otherzip.label"
                    :readonly="false"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
                <b-col lg="3" md="3">
                  <inline-input
                    :id="controls.otherpobox.id"
                    :value="data.otherpobox"
                    :label="controls.otherpobox.label"
                    :readonly="false"
                    :mode="mode"
                    :required="false"
                    @changed="updateField"
                  />
                </b-col>
              </b-row>
              <hr />
            </b-collapse>

            <font-awesome-icon icon="chevron-down" />
            <h5 v-b-toggle.collapse-3 style="display:inline;cursor: pointer">
              Description
            </h5>
            <b-collapse id="collapse-3" class="mt-2">
              <b-row>
                <b-col lg="12" md="12">
                  <inline-input
                    :id="controls.description.id"
                    :value="data.description"
                    :hide-label="true"
                    :label="controls.description.label"
                    :readonly="false"
                    :mode="mode"
                    :required="false"
                    :is-text-area="true"
                    @changed="updateField"
                  />
                </b-col>
              </b-row>
            </b-collapse>
          </form>
        </b-tab>
        <b-tab
          :title="controls.tabs.activities.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
        >
          <activities-table :contact-id="id" @loaded="onActivitiesLoad" />
        </b-tab>

        <b-tab
          :title="controls.tabs.files.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
        >
          <files-container
            :module-id="$route.meta.module.id"
            :entity-id="id"
            @loaded="onFileContainerLoad"
          />
        </b-tab>

        <b-tab
          :title="controls.tabs.history.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
        >
          <contact-history-table :contact-id="id" @loaded="onHistoryLoad" />
        </b-tab>
        <b-tab
          :title="controls.tabs.dataHistory.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
        >
          <data-history-table
            module="contacts"
            :entity-id="id"
            @loaded="onDataHistoryLoad"
          />
        </b-tab>
      </b-tabs>

      <form-submission-actions
        v-if="action !== 'preview'"
        :mode="mode"
        :loading="{ save: saveInProgress, saveAndView: saveAndViewInProgress }"
        :buttons-visibility="{
          previous: pagination.prev,
          next: pagination.next
        }"
        @previous-item="
          $router.push({
            name: $route.name,
            params: {
              action: $route.params.action,
              id: pagination.prev.id,
              page: pagination.prev.page
            }
          })
        "
        @next-item="
          $router.push({
            name: $route.name,
            params: {
              action: $route.params.action,
              id: pagination.next.id,
              page: pagination.next.page
            }
          })
        "
        @save="
          save('tabular').then(response =>
            // response ? $router.push({ name: 'Contacts' }) : false
            response
              ? $router.push($store.getters['router/previousRoute'])
              : false
          )
        "
        @save-and-view="
          save('view').then(response =>
            response
              ? $router.push({
                  name: 'Contact submission',
                  params: { action: 'view', id: response }
                })
              : false
          )
        "
        @edit="
          $router.push({
            name: 'Contact submission',
            params: { action: 'edit', id: id }
          })
        "
        @back="$router.push($store.getters['router/previousRoute'])"
      />
    </b-card>

    <b-modal
      ref="account-preview-modal"
      centered
      scrollable
      size="lg"
      :title="accountPreviewModal.title"
    >
      <b-container fluid class="ma-0 pa-0" style="height: 80vh;">
        <AccountSubmissionForm :id="accountPreviewModal.id" action="preview" />
      </b-container>

      <template #modal-footer="">
        <hr />
        <div class="form-row d-flex  justify-content-end">
          <b-button
            variant="outline-dark"
            class="m-1"
            @click="openAccountInNewWindow(accountPreviewModal.id)"
          >
            <font-awesome-icon icon="share" /> Open in new window
          </b-button>
          <b-button
            variant="outline-dark"
            class="m-1"
            @click="closeAccountPreview()"
          >
            <font-awesome-icon icon="times" /> Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";

import InlineInput from "@/components/InlineInput";
import InlineSelect from "@/components/InlineSelectMultiple";

import CKEditor from "@ckeditor/ckeditor5-vue";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";

import AccountSubmissionForm from "@/views/RelationshipManagement/AccountSubmissionForm/index.js";
import ActivitiesTable from "@/views/AccountTouchpoints/ActivitiesTable";

import ContactHistoryTable from "./ContactHistoryTable";

import DataHistoryTable from "@/components/DataHistoryTable";

Vue.use(CKEditor);

import FormSubmissionActions from "@/components/FormSubmissionActions";

import FilesContainer from "@/components/FilesContainer";

import sharedEvents from "@/shared/sharedEvents.js";

import { mapState, mapActions } from "vuex";

export default {
  name: "ContactSubmissionForm",
  props: {
    id: {
      type: String,
      default: ""
    },
    action: {
      type: String,
      default: ""
    },
    page: {
      type: String,
      default: ""
    }
  },
  components: {
    InlineInput,
    InlineSelect,
    FilesContainer,
    AccountSubmissionForm,
    ActivitiesTable,
    FormSubmissionActions,
    ContactHistoryTable,
    DataHistoryTable
  },
  data: function() {
    return {
      editor: InlineEditor,
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      sameAsAccountAddress: false,
      singleAccountSelected: false,
      mode: undefined,
      accountPreviewModal: {
        id: "",
        title: ""
      },
      controls: {
        tabs: {
          activities: {
            title: "Activities"
          },
          files: {
            title: "Files"
          },
          history: {
            title: "Accounts History"
          },
          dataHistory: {
            title: "Data History"
          }
        },
        buttons: {
          edit: {
            id: "button:details_edit"
          },
          save: {
            id: "button:details_save"
          }
        },
        salutation: {
          id: "input:salutation",
          label: "Salutation",
          changed: false,
          readonly: false
        },
        firstname: {
          id: "input:firstname",
          label: "First Name",
          changed: false,
          readonly: false
        },
        lastname: {
          id: "input:lastname",
          label: "Last Name",
          changed: false,
          readonly: false
        },
        phone: {
          id: "input:phone",
          label: "Phone",
          changed: false,
          readonly: false
        },
        mobile: {
          id: "input:mobile",
          label: "Mobile",
          changed: false,
          readonly: false
        },
        fax: {
          id: "input:fax",
          label: "Fax",
          changed: false,
          readonly: false
        },
        email: {
          id: "input:email",
          label: "Email",
          changed: false,
          readonly: false
        },
        secondaryemail: {
          id: "input:secondaryemail",
          label: "Secondary Email",
          changed: false,
          readonly: false
        },
        emailoptout: {
          id: "select:emailoptout",
          label: "Email Opt Out",
          changed: false,
          readonly: false,
          options: []
        },
        donotcall: {
          id: "select:donotcall",
          label: "Do Not Call",
          changed: false,
          readonly: false,
          options: []
        },
        assigned_to: {
          id: "select:assigned_to",
          label: "Assigned To",
          changed: false,
          readonly: false,
          options: []
        },
        title: {
          id: "input:title",
          label: "Title",
          changed: false,
          readonly: false
        },
        department: {
          id: "input:department",
          label: "Department",
          changed: false,
          readonly: false
        },
        street: {
          id: "input:street",
          label: "Mailing Street",
          changed: false,
          readonly: false
        },
        country: {
          id: "select:country",
          label: "Mailing Country",
          options: [],
          changed: false,
          readonly: false
        },
        state: {
          id: "select:state",
          label: "Mailing State",
          options: [],
          changed: false,
          readonly: false
        },
        city: {
          id: "select:city",
          label: "Mailing City",
          options: [],
          changed: false,
          readonly: false
        },
        zip: {
          id: "input:zip",
          label: "Mailing Postal Code",
          changed: false,
          readonly: false
        },
        pobox: {
          id: "input:pobox",
          label: "Mailing PO Box",
          changed: false,
          readonly: false
        },
        otherstreet: {
          id: "input:otherstreet",
          label: "Other Street",
          changed: false,
          readonly: false
        },
        othercountry: {
          id: "select:othercountry",
          label: "Other Country",
          options: [],
          changed: false,
          readonly: false
        },
        otherstate: {
          id: "select:otherstate",
          label: "Other State",
          options: [],
          changed: false,
          readonly: false
        },
        othercity: {
          id: "select:othercity",
          label: "Other City",
          options: [],
          changed: false,
          readonly: false
        },
        otherzip: {
          id: "input:otherzip",
          label: "Other Postal Code",
          changed: false,
          readonly: false
        },
        otherpobox: {
          id: "input:otherpobox",
          label: "Other PO Box",
          changed: false,
          readonly: false
        },
        description: {
          id: "input:description",
          label: "Description",
          changed: false,
          readonly: false
        },
        account: {
          id: "select:account",
          label: "Account",
          options: [],
          changed: false,
          readonly: false,
          loading: false
        },
        created: {
          id: "input:created",
          label: "Created",
          changed: false,
          readonly: true
        },
        modified: {
          id: "input:modified",
          label: "Modified",
          changed: false,
          readonly: true
        }
      },
      data: {
        id: "",
        salutation: "",
        firstname: "",
        lastname: "",
        phone: "",
        mobile: "",
        fax: "",
        email: "",
        emailoptout: {
          id: "",
          label: ""
        },
        donotcall: {
          id: "",
          label: ""
        },
        assigned_to: {
          id: "",
          label: ""
        },
        title: "",
        department: "",
        street: "",
        country: {
          id: "",
          label: ""
        },
        state: {
          id: "",
          label: ""
        },
        city: {
          id: "",
          label: ""
        },
        zip: "",
        pobox: "",
        otherstreet: "",
        othercountry: {
          id: "",
          label: ""
        },
        otherstate: {
          id: "",
          label: ""
        },
        othercity: {
          id: "",
          label: ""
        },
        otherzip: "",
        otherpobox: "",
        description: "",
        account: [],
        created: "",
        modified: ""
      },
      districts: [],
      pagination: {
        prev: false,
        next: false
      }
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created() {
    this.initialize();
  },
  mounted: function() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    onActivitiesLoad(count) {
      if (count > 0)
        this.controls.tabs.activities.title = `Activities (${count})`;
    },
    onHistoryLoad(count) {
      if (count > 0)
        this.controls.tabs.history.title = `Accounts History (${count})`;
    },
    onDataHistoryLoad(count) {
      if (count > 0)
        this.controls.tabs.dataHistory.title = `Data History (${count})`;
    },

    onAccountLinkClicked(e) {
      this.accountPreviewModal.id = e.id;
      this.accountPreviewModal.title = `Account ${e.label}`;

      this.$refs["account-preview-modal"].show();
    },
    openAccountPreview() {
      this.$refs["account-preview-modal"].show();
    },
    closeAccountPreview: function() {
      this.$refs["account-preview-modal"].hide();
    },
    openAccountInNewWindow(account_id) {
      let routeData = this.$router.resolve({
        name: "Account submission",
        params: {
          id: account_id,
          action: "view"
        }
      });
      window.open(routeData.href, "_blank");
    },
    onAccountAddressCheckChange: async function(value) {
      if (!value) {
        this.data.country = {
          id: "",
          label: ""
        };
        this.data.state = {
          id: "",
          label: ""
        };
        this.data.city = {
          id: "",
          label: ""
        };

        this.data.street = "";
        this.data.zip = "";
        this.data.pobox = "";

        return;
      }

      let response = await this.$api.get("accounts/" + this.data.account[0].id);

      this.data.country = {
        id: response["Country"],
        label: response["Country"]
      };
      this.data.state = {
        id: response["State"],
        label: response["State"]
      };
      this.data.city = {
        id: response["City"],
        label: response["City"]
      };

      this.data.street = response["Street"];
      this.data.zip = response["Postal Code"];
      this.data.pobox = response["PO Box"];
      this.data.created = response["Created"];
      this.data.modified = response["Modified"];
    },
    onFileContainerLoad(count) {
      if (count > 0) this.controls.tabs.files.title = `Files (${count})`;
    },
    initialize: async function() {
      let self = this;

      if (this.action === "create") {
        this.mode = this.$constants.FORM_MODE.CREATE;
      }
      if (this.action === "edit") {
        this.mode = this.$constants.FORM_MODE.EDIT;
      }
      if (this.action === "view") {
        this.mode = this.$constants.FORM_MODE.VIEW;
      }

      this.data.id = this.id;

      this.$api.get("users").then(response => {
        self.controls.assigned_to.options = response.map(u => ({
          id: u.id,
          label: u.full_name
        }));

        if (this.mode === this.$constants.FORM_MODE.CREATE) {
          this.data.assigned_to = {
            id: this.profile.data.id.toString(),
            label:
              this.profile.data.first_name + " " + this.profile.data.last_name
          };
        }
      });

      self.controls.emailoptout.options = [
        {
          id: "0",
          label: "No"
        },
        {
          id: "1",
          label: "Yes"
        }
      ];
      self.controls.donotcall.options = [
        {
          id: "0",
          label: "No"
        },
        {
          id: "1",
          label: "Yes"
        }
      ];

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        //make request and save to session to avoid several requests to server from buttons
        this.$customTable
          .getPrevNextServerKeys({
            module: this.$route.meta.module,
            id: this.id,
            page: this.page
          })
          .then(response => (self.pagination = response));

        await this.fetchContact(this.id);
      }

      this.$api
        .post("dictionaries/countries/mapped", {
          ignore_restrictions: true
        })
        .then(response => {
          self.districts = response;

          self.controls.country.options = self.$helpers.getDistinctArray(
            response,
            "country",
            "country",
            "id",
            "label"
          );
          self.controls.state.options = self.$helpers.getDistinctArray(
            response.filter(s => s.country === self.data.country.id),
            "state",
            "state",
            "id",
            "label"
          );
          self.controls.city.options = self.$helpers.getDistinctArray(
            response.filter(s => s.state === self.data.state.id),
            "city",
            "city",
            "id",
            "label"
          );

          self.controls.othercountry.options = self.$helpers.getDistinctArray(
            response,
            "country",
            "country",
            "id",
            "label"
          );
          self.controls.otherstate.options = self.$helpers.getDistinctArray(
            response.filter(s => s.country === self.data.othercountry.id),
            "state",
            "state",
            "id",
            "label"
          );
          self.controls.othercity.options = self.$helpers.getDistinctArray(
            response.filter(s => s.state === self.data.otherstate.id),
            "city",
            "city",
            "id",
            "label"
          );
        });
    },
    onAccountSearch(payload) {
      let self = this;

      self.controls.account.loading = true;

      this.$api
        .post("dictionaries/accounts", {
          norestrict: true,
          query: payload.query,
          starts_with: payload.startsWith
        })
        .then(response => {
          self.controls.account.loading = false;

          self.controls.account.options = response.map(u => ({
            id: u.id,
            label: u.name
          }));
        });
    },
    edit() {},

    save(_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      let method = "";

      let url = "contacts";
      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = "put";
        url = "/contacts";
      }
      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        method = "put";
        url = `/contacts/${this.data.id}`;
      }

      this.isLoading = true;
      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          if (this.mode === this.$constants.FORM_MODE.CREATE) {
            sharedEvents.emit("newContactCreated");
          }

          this.$form.makeToastInfo(response.message);

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastError(error.message);

          return Promise.resolve(false);
        });
    },
    updateField(field, value) {
      let self = this;
      let params = {};
      params["id"] = this.id;
      params[field] = value;

      //update local data values - it can be used in Edit action
      self.data[field] = value;

      //if control in View mode then update database

      self.data[field] = value;

      if (this.mode === this.$constants.FORMCONTROLMODE.VIEW)
        this.$api
          .put(`contacts/${this.data.id}`, params)
          .then(response => {
            self.$form.makeToastInfo(`${field}: ${response.message}`);

            self.controls[field].changed = false;

            this.initialize();
          })
          .catch(response => {
            console.log(response);

            self.$form.makeToastError(`${field}: ${response.message}`);
          });
    },
    updateSelect(id, value) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      if (id === "account") {
        this.singleAccountSelected = value.length === 1;
      }

      this.updateField(id, value);
    },

    fetchContact: async function(id) {
      let self = this;

      this.isLoading = true;
      return this.$api
        .get(`/contacts/${id}`)
        .then(response => {
          this.isLoading = false;
          if (self.$_.isEmpty(response)) return;

          self.data.id = self.id;

          self.data.salutation = response["Salutation"];
          self.data.firstname = response["First Name"];
          self.data.lastname = response["Last Name"];
          self.data.phone = response["Phone"];
          self.data.mobile = response["Mobile"];
          self.data.fax = response["Fax"];
          self.data.email = response["Email"];
          self.data.secondaryemail = response["Secondary Email"];

          self.data.emailoptout = {
            id: self.controls.emailoptout.options.find(
              m => m.label === response["Email Opt Out"]
            ).id,
            label: self.controls.emailoptout.options.find(
              m => m.label === response["Email Opt Out"]
            ).label
          };
          self.data.donotcall = {
            id: self.controls.donotcall.options.find(
              m => m.label === response["Do Not Call"]
            ).id,
            label: self.controls.donotcall.options.find(
              m => m.label === response["Do Not Call"]
            ).label
          };
          self.data.title = response["Title"];
          self.data.department = response["Department"];

          self.data.assigned_to = {
            id: response["assigned_to_id"],
            label: response["Assigned To"]
          };

          self.data.street = response["Street"];
          self.data.country = {
            id: response["Country"],
            label: response["Country"]
          };
          self.data.state = {
            id: response["State"],
            label: response["State"]
          };
          self.data.city = {
            id: response["City"],
            label: response["City"]
          };
          self.data.zip = response["Zip"];
          self.data.pobox = response["PO Box"];

          self.data.otherstreet = response["Other Street"];
          self.data.othercountry = {
            id: response["Other Country"],
            label: response["Other Country"]
          };
          self.data.otherstate = {
            id: response["Other State"],
            label: response["Other State"]
          };
          self.data.othercity = {
            id: response["Other City"],
            label: response["Other City"]
          };
          self.data.otherzip = response["Other Zip"];
          self.data.otherpobox = response["Other PO Box"];

          self.data.description = response["Description"];

          self.data.created = response["Created"];
          self.data.modified = response["Modified"];

          if (response["account_ids"]) {
            self.data.account = [];

            let account_ids = response["account_ids"].split(",");
            let account_names = response["Account Name"].split(",");

            for (let i = 0; i < account_ids.length; i++) {
              self.data.account.push({
                id: account_ids[i].trim(),
                label: account_names[i].trim()
              });
            }
          }
        })
        .catch(error => {
          this.isLoading = false;
          console.log(error);
        });
    }
  },
  watch: {
    "data.country": function(newVal) {
      if (newVal.id) {
        this.controls.state.options = this.$helpers.getDistinctArray(
          this.districts.filter(s => s.country === newVal.id),
          "state",
          "state",
          "id",
          "label"
        );
      }
    },
    "data.state": function(newVal) {
      if (newVal.id) {
        this.controls.city.options = this.$helpers.getDistinctArray(
          this.districts.filter(s => s.state === newVal.id),
          "city",
          "city",
          "id",
          "label"
        );
      }
    },
    "data.othercountry": function(newVal) {
      if (newVal.id) {
        this.controls.otherstate.options = this.$helpers.getDistinctArray(
          this.districts.filter(s => s.country === newVal.id),
          "state",
          "state",
          "id",
          "label"
        );
      }
    },
    "data.otherstate": function(newVal) {
      if (newVal.id) {
        this.controls.othercity.options = this.$helpers.getDistinctArray(
          this.districts.filter(s => s.state === newVal.id),
          "city",
          "city",
          "id",
          "label"
        );
      }
    }
  }
};
</script>

<style scoped>
/*account preview modal resize */
::v-deep .modal-lg {
  max-width: 70vw !important;
  max-height: 70vh !important;
}

/***********************/
</style>
