<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`${$customTable.getCustomTableName(module || $route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @view-item="viewItem"
      @edit-item="editItem"
      @delete-item="deleteItem"
      @filter="onFilter"
      @inline-update="onInlineUpdate"
    >
      <div slot="afterFilter">
        <b-row v-if="showAddButton">
          <b-col>
            <b-button-group>
              <b-button
                variant="outline-dark"
                title="Add Invoice"
                @click="addItem()"
              >
                <font-awesome-icon icon="plus" /> Create
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </div>
    </table-custom>
  </div>
</template>

<script>
import TableCustom from "@/components/TableCustom";

//import Vue from "vue";

export default {
  props: {
    accountId: {
      type: [Number, String],
      default: ""
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    module: {
      type: Object,
      default: undefined
    }
  },
  name: "InvoicesTable",
  components: {
    TableCustom
  },
  data: function() {
    return {
      rawData: {},
      appliedFilters: [],
      dataTable: {
        //loading: false,
        isLoading: false,
        dataSet: [],
        options: {
          //showOnLoading: true,
          columns: [
            "ID",
            "Invoice No",
            "Subject",
            "Account Name",
            "Invoice Date",
            "Total",
            "Assigned To",
            "Country",
            "City",
            "State",
            "Street",
            "Postal Code",
            "PO Box",
            "Description",
            "Modified Time"
          ],
          filterable: [
            "ID",
            "Invoice No",
            "Account Name",
            "Subject",
            "Invoice Date",
            "Total",
            "Assigned To"
          ],
          editableColumns: ["Subject", "Invoice Date", "Assigned To"],
          dropdownColumns: [{ name: "Assigned To", options: [] }],

          formatColumns: [
            {
              name: "Total",
              style: {
                style: "currency",
                currency: "USD"
              }
            }
          ],
          totalColumns: [
            {
              name: "Count",
              method: "count"
            },
            {
              name: "Total",
              alias: "Amount",
              style: {
                style: "currency",
                currency: "USD"
              }
            }
          ],
          footerHeadings: false,
          perPage: 50,
          showActions: true
        }
      }
    };
  },
  computed: {},
  mounted() {
    if (this.accountId) this.getData();

    this.fetchDropdowns();
  },
  methods: {
    fetchDropdowns() {
      let self = this;

      this.$api.get("users").then(response => {
        self.dataTable.options.dropdownColumns.find(
          c => c.name === "Assigned To"
        ).options = response.map(u => ({ id: u.ID, label: u.Name }));
      });
    },
    onFilter() {},
    async getData(payload) {
      let self = this;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      this.appliedFilters = payload;

      let method = "",
        url = "";

      if (this.accountId) {
        method = "get";
        url = `accounts/${this.accountId}/invoices`;
      } else {
        method = "post";
        url = "invoices";
      }

      return this.$api[method](url, payload)
        .then(response => {
          self.dataTable.isLoading = false;

          self.$emit("loaded", response.length);

          self.dataTable.dataSet = response;

          if (response.length === 0) return;

          //20210131 #316 global search
          //self.$emit("loaded", self.dataTable.dataSet.length)
        })
        .catch(error => {
          console.log(error);
          self.dataTable.isLoading = false;
          self.$form.msgBoxOk("Error occured");
        });
    },
    addItem: function() {
      this.$router.push({
        name: "Invoice submission",
        params: {
          action: "create"
        }
      });
    },
    viewItem: function(id) {
      this.$router.push({
        name: "Invoice submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    editItem: function(id) {
      this.$router.push({
        name: "Invoice submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    deleteItem: async function(id) {
      let item = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        `Invoice #${item["Invoice No"]} will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.$api
        .delete(`invoices/${item.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData(self.appliedFilters);
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.message);
        });
    },
    onInlineUpdate(e) {
      const columnsMapping = [
        { alias: "Subject", dbName: "subject" },
        { alias: "Invoice Date", dbName: "invoice_date" },
        { alias: "Assigned To", dbName: "assigned_to" }
      ];

      let value = e.value;
      let dbName = columnsMapping.find(m => m.alias === e.column).dbName;

      this.updateField(e.id, dbName, value);
    },
    updateField(id, field, value) {
      let self = this;
      let params = {};
      params["id"] = id;
      params[field] = value;

      this.$api
        .put(`invoices/${id}`, params)
        .then(response => {
          self.$form.makeToastInfo(`${field}: ${response.message}`);
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(`${field}: ${response.message}`);
        });
    }
  },
  watch: {}
};
</script>

<style scoped>
/*
::v-deep .table-responsive {
  overflow: visible !important;
}
::v-deep table {
  overflow: visible !important;
}*/
</style>
