<template>
  <b-modal
    ref="modal"
    centered
    scrollable
    size="lg"
    :title="modal.title"
    hide-footer
  >
    <b-container fluid class="ma-0 pa-0 overflow-auto" style="height: 70vh">
      <ProjectSubmissionForm :id="modal.id" action="preview" />
    </b-container>

    <hr />

    <div class="form-row d-flex justify-content-end">
      <b-button
        variant="outline-dark"
        class="m-1"
        @click="openInNewWindow(modal.id)"
      >
        <font-awesome-icon icon="share" /> Open in new window
      </b-button>

      <b-button variant="outline-dark" class="m-1" @click="close()">
        <font-awesome-icon icon="times" /> Close
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import ProjectSubmissionForm from "./ProjectSubmissionForm";

export default {
  Name: "ProjectPreviewModal",
  components: {
    ProjectSubmissionForm
  },
  data: function() {
    return {
      modal: {
        id: "",
        title: ""
      }
    };
  },
  methods: {
    show(e) {
      this.modal.id = e.id;
      this.modal.title = `Project #${e.id}`;

      this.$refs["modal"].show();
    },
    close() {
      this.$refs["modal"].hide();
    },
    openInNewWindow(id) {
      let routeData = this.$router.resolve({
        name: "Project submission",
        params: {
          id: id,
          action: "view"
        }
      });
      window.open(routeData.href, "_blank");
    }
  }
};
</script>

<style></style>
