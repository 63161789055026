var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('table-custom', {
    ref: "dataTable",
    attrs: {
      "mode": "server",
      "name": "".concat(_vm.$customTable.getCustomTableName(_vm.module || _vm.$route.meta.module)),
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    on: {
      "pagination": function pagination($event) {
        return _vm.getData(_vm.appliedFilters, $event);
      },
      "sort": function sort($event) {
        return _vm.getData(_vm.appliedFilters, $event);
      },
      "server-filter": function serverFilter($event) {
        return _vm.getData(_vm.appliedFilters, $event);
      },
      "inline-update": _vm.onInlineUpdate
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('button', {
          staticClass: "btn btn-primary btn-sm",
          on: {
            "click": function click($event) {
              return _vm.viewItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "eye"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-success btn-sm",
          on: {
            "click": function click($event) {
              return _vm.editItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "pencil-alt"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": function click($event) {
              return _vm.deleteItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1), _c('button', {
          staticClass: "btn btn-dark btn-sm",
          attrs: {
            "title": "Preview"
          },
          on: {
            "click": function click($event) {
              return _vm.viewContent(props.row);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "search"
          }
        })], 1), _c('button', {
          class: [props.row['FilesCount'] !== '0' ? 'btn btn-secondary btn-sm' : 'btn btn-outline-secondary btn-sm'],
          on: {
            "click": function click($event) {
              return _vm.toggleFiles(props.row['ID']);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "download"
          }
        })], 1)])]);
      }
    }, {
      key: "child_row",
      fn: function fn(props) {
        return _c('span', {}, [_c('files-container', {
          ref: "files",
          attrs: {
            "module-id": _vm.$route.meta.module.id,
            "entity-id": props.row.ID
          }
        })], 1);
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "afterFilter"
    },
    slot: "afterFilter"
  }, [this.showAddButton ? _c('b-row', [_c('b-col', [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Add Contact"
    },
    on: {
      "click": function click($event) {
        return _vm.addItem();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  }), _vm._v(" Create ")], 1), _c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Add Contact Inline"
    },
    on: {
      "click": function click($event) {
        return _vm.addItemInline();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus-square"
    }
  }), _vm._v(" Create inline ")], 1), _c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Quick Add Contact"
    },
    on: {
      "click": function click($event) {
        return _vm.addItemQuick();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "bolt"
    }
  }), _vm._v(" Create quick ")], 1)], 1)], 1)], 1) : _vm._e()], 1)]), _c('b-modal', {
    ref: "preview-modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": "lg",
      "title": _vm.previewModal.name
    }
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', {
    staticClass: "font-weight-bolder",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Name: ")]), _c('b-col', [_vm._v(_vm._s(_vm.previewModal.name))])], 1), _c('b-row', [_c('b-col', {
    staticClass: "font-weight-bolder",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Title: ")]), _c('b-col', [_vm._v(_vm._s(_vm.previewModal.title))])], 1), _c('b-row', [_c('b-col', {
    staticClass: "font-weight-bolder",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Department: ")]), _c('b-col', [_vm._v(_vm._s(_vm.previewModal.department))])], 1), _c('b-row', [_c('b-col', {
    staticClass: "font-weight-bolder",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Email: ")]), _c('b-col', [_vm._v(_vm._s(_vm.previewModal.email))])], 1), _c('b-row', [_c('b-col', {
    staticClass: "font-weight-bolder",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Phone: ")]), _c('b-col', [_vm._v(_vm._s(_vm.previewModal.phone))])], 1), _c('b-row', [_c('b-col', {
    staticClass: "font-weight-bolder",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Mobile: ")]), _c('b-col', [_vm._v(_vm._s(_vm.previewModal.mobile))])], 1), _c('b-row', [_c('b-col', {
    staticClass: "font-weight-bolder",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" Fax: ")]), _c('b-col', [_vm._v(_vm._s(_vm.previewModal.fax))])], 1)], 1)], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }