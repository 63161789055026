<template>
  <div class="animated fadeIn">
    <TableWrapper
      :ref="model.name"
      :model="model"
      actions-mode="event"
      :columns="tableColumns"
      :read-only="false"
      :disable-inline-editing="true"
      :filter-by-column="true"
      @loaded="onTableLoad"
      @view="onViewItem"
    >
    </TableWrapper>
  </div>
</template>

<script>
import models from "@/models";
import TableWrapper from "@/components/DataView/TableWrapper.vue";

export default {
  props: {
    accountId: {
      type: [Number, String],
      default: undefined
    }
  },
  name: "MarketsTable",
  components: { TableWrapper },
  data: function() {
    return {
      model: models.distributor.distributorMarkets,
      tableColumns: ["Market", "Country", "State"]
    };
  },
  computed: {},
  created() {},
  mounted() {
    let payload = {
      account_id: this.accountId
    };

    this.$refs[this.model.name].getData(payload);
  },
  methods: {
    onTableLoad(e) {
      this.$emit("loaded", e);
    },
    onViewItem(e) {
      this.$router.push({
        name: "WH national overview",
        params: {
          accountFilter: e.row["Account Name"],
          marketFilter: e.row["Market"]
        }
      });
    }
  },
  watch: {}
};
</script>

<style></style>
