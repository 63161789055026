//It works for single method and event. Rebuild to multiple
//It works just between different tabs!

const sharedEvents = {
  method: {},
  event: "",
  subscribe: (_method, _event) => {
    sessionStorage.removeItem("events");

    sharedEvents.method = _method;
    sharedEvents.event = _event;

    window.addEventListener("storage", sharedEvents.onNewEvent);
  },

  destroy: _method => {
    window.removeEventListener("storage", _method);
    sessionStorage.removeItem("events");
  },
  emit: _event => {
    let obj = {};

    obj[_event] = true;

    sessionStorage.events = JSON.stringify(obj);
  },
  onNewEvent: event => {
    let e = JSON.parse(event.newValue);

    if (e && e[sharedEvents.event]) {
      sessionStorage.removeItem("events");

      sharedEvents.method(sharedEvents.event);
    }
  }
};

export default sharedEvents;
