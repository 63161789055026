<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`custom_table_data_history`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
    />
  </div>
</template>

<script>
import TableCustom from "@/components/TableCustom";

export default {
  props: {
    module: {
      type: String,
      default: ""
    },
    entityId: {
      type: [String, Number],
      default: ""
    }
  },
  name: "DataHistoryTable",
  components: {
    TableCustom
  },
  data: function() {
    return {
      rawData: {},
      dataTable: {
        //loading: false,
        isLoading: false,
        dataSet: [],
        options: {
          columns: [
            "Field Name",
            "Old Value",
            "New Value",
            "Modified By",
            "Modification Date"
          ],
          filterable: [
            "Field Name",
            "Old Value",
            "New Value",
            "Modified By",
            "Modification Date"
          ],

          footerHeadings: false,
          perPage: 50,
          disablePerPageDropdown: false,
          showActions: false
        }
      }
    };
  },
  computed: {},
  created() {},
  mounted() {
    if (this.module === "projects") {
      this.dataTable.options.columns = [
        "Task Name",
        "Field Name",
        "Old Value",
        "New Value",
        "Modified By",
        "Modification Date"
      ];

      this.dataTable.options.filterable = this.dataTable.options.columns;
    }

    if (this.entityId) this.getData();
  },
  methods: {
    onFilter() {},

    async getData() {
      let self = this;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      let response = [];

      try {
        response = await this.$api.get(
          `${this.module}/${this.entityId}/data-history`
        );

        self.dataTable.isLoading = false;

        self.dataTable.dataSet = response;

        if (response.length === 0) return;

        self.$emit("loaded", self.dataTable.dataSet.length);
      } catch (error) {
        console.log(error);
        self.dataTable.isLoading = false;
        self.$form.msgBoxOk("Error occured");
      }
    }
  },
  watch: {}
};
</script>

<style scoped></style>
