<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      mode="server"
      :name="`${$customTable.getCustomTableName(module || $route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @pagination="getData(appliedFilters, $event)"
      @sort="getData(appliedFilters, $event)"
      @server-filter="getData(appliedFilters, $event)"
      @inline-update="onInlineUpdate"
    >
      <div slot="afterFilter">
        <b-row v-if="this.showAddButton">
          <b-col>
            <b-button-group>
              <b-button
                variant="outline-dark"
                title="Add Contact"
                @click="addItem()"
              >
                <font-awesome-icon icon="plus" /> Create
              </b-button>

              <b-button
                variant="outline-dark"
                title="Add Contact Inline"
                @click="addItemInline()"
              >
                <font-awesome-icon icon="plus-square" /> Create inline
              </b-button>

              <b-button
                variant="outline-dark"
                title="Quick Add Contact"
                @click="addItemQuick()"
              >
                <font-awesome-icon icon="bolt" /> Create quick
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </div>
      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            class="btn btn-primary btn-sm"
            @click="viewItem(props.row.ID)"
          >
            <font-awesome-icon icon="eye" />
          </button>
          <button
            class="btn btn-success btn-sm"
            @click="editItem(props.row.ID)"
          >
            <font-awesome-icon icon="pencil-alt" />
          </button>
          <button
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row.ID)"
          >
            <font-awesome-icon icon="trash" />
          </button>
          <button
            class="btn btn-dark btn-sm"
            @click="viewContent(props.row)"
            title="Preview"
          >
            <font-awesome-icon icon="search" />
          </button>
          <button
            :class="[
              props.row['FilesCount'] !== '0'
                ? 'btn btn-secondary btn-sm'
                : 'btn btn-outline-secondary btn-sm'
            ]"
            @click="toggleFiles(props.row['ID'])"
          >
            <font-awesome-icon icon="download" />
          </button>
        </div>
      </div>
      <span slot-scope="props" slot="child_row">
        <files-container
          ref="files"
          :module-id="$route.meta.module.id"
          :entity-id="props.row.ID"
        />
      </span>
    </table-custom>

    <b-modal
      ref="preview-modal"
      centered
      hide-footer
      size="lg"
      :title="previewModal.name"
    >
      <b-card>
        <b-card-body>
          <b-row>
            <b-col cols="2" class="font-weight-bolder">
              Name:
            </b-col>
            <b-col>{{ previewModal.name }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="2" class="font-weight-bolder">
              Title:
            </b-col>
            <b-col>{{ previewModal.title }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="2" class="font-weight-bolder">
              Department:
            </b-col>
            <b-col>{{ previewModal.department }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="2" class="font-weight-bolder">
              Email:
            </b-col>
            <b-col>{{ previewModal.email }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="2" class="font-weight-bolder">
              Phone:
            </b-col>
            <b-col>{{ previewModal.phone }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="2" class="font-weight-bolder">
              Mobile:
            </b-col>
            <b-col>{{ previewModal.mobile }}</b-col>
          </b-row>
          <b-row>
            <b-col cols="2" class="font-weight-bolder">
              Fax:
            </b-col>
            <b-col>{{ previewModal.fax }}</b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <hr />
      <div class="form-row d-flex  justify-content-end">
        <b-button variant="outline-dark" class="m-1" @click="closeModal()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FilesContainer from "@/components/FilesContainer";

import TableCustom from "@/components/TableCustom";

import Vue from "vue";

export default {
  props: {
    accountId: {
      type: [Number, String],
      default: undefined
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    hideSettingsBar: {
      type: Boolean,
      default: false
    },

    perPage: {
      type: Number,
      default: 50
    },
    autoload: {
      type: Boolean,
      default: false
    },
    filter: {
      type: Array,
      default: () => {
        return [];
      }
    },
    module: {
      type: Object,
      default: undefined
    }
  },

  name: "Contacts",
  components: {
    FilesContainer,
    TableCustom
  },
  data: function() {
    return {
      rawData: {},
      appliedFilters: [],
      dataTable: {
        isInserting: false,
        isLoading: false,
        options: {
          columns: [
            "ID",
            "Contact Name",
            "Email",
            "Phone",

            "Account Name",
            "Assigned To",
            "Country",
            "State",
            "City",
            "Modified",
            "Actions"
          ],
          filterable: [
            "ID",
            "Contact Name",
            "Email",
            "Phone",

            "Account Name",
            "Assigned To",
            "Country",
            "State",
            "City",

            "Modified"
          ],
          editableColumns: [
            "Contact Name",
            "Email",
            "Phone",
            "Mobile",
            "Assigned To"
          ],
          dropdownColumns: [{ name: "Assigned To", options: [] }],
          uniqueKey: "ID",
          showChildRowToggler: false,
          filterByColumn: true,
          perPage: this.perPage,
          perPageValues: [],
          showCustomActions: true,
          showChildRows: true,
          selectableRows: this.selectable,
          hideSettingsBar: this.hideSettingsBar,
          saveNewRecordCallback: this.saveNewRecordCallback,
          revertNewRecordCallback: this.revertNewRecordCallback
        },
        dropdowns: [
          {
            name: "Account Name",
            options: [],
            value: [],
            multiple: true,
            allowEmpty: false,
            async: true,
            loading: false,
            startsWith: false
          },
          {
            name: "Assigned To",
            options: [],
            value: [],
            multiple: false,
            allowEmpty: false
          },
          {
            name: "Country",
            options: [],
            value: [],
            multiple: false,
            allowEmpty: false
          },
          {
            name: "State",
            options: [],
            value: [],
            multiple: false,
            allowEmpty: false
          },
          {
            name: "City",
            options: [],
            value: [],
            multiple: false,
            allowEmpty: false
          }
        ],

        dataSet: [],
        onRowClick: function() {},
        totalRecords: 0
      },
      previewModal: {
        ID: "",
        name: "",
        title: "",
        department: "",
        email: "",
        phone: "",
        mobile: "",
        fax: ""
      }
    };
  },
  computed: {},
  mounted() {
    this.getDropdowns();

    if (this.accountId || this.autoload) this.getData();
  },
  methods: {
    onAccountSearch(payload) {
      let self = this;

      self.dropdown("Account Name").loading = true;

      this.$api
        .post("dictionaries/accounts", {
          norestrict: true,
          query: payload.query,
          starts_with: payload.startsWith
        })
        .then(response => {
          self.dropdown("Account Name").loading = false;

          self.dropdown("Account Name").options = response.map(u => ({
            id: u.id,
            label: u.name
          }));
        });
    },
    dropdown(_name) {
      return this.dataTable.dropdowns.find(n => n.name === _name);
    },
    rowDropdown(_row, _name) {
      return _row.dropdowns.find(d => d.name === _name);
    },
    onAccountChangeSelect(_id, _value) {
      this.onChangeSelect(_value, _id);
    },
    onChangeSelect(_value, _id) {
      let id = _id.split(":")[0];
      let column = _id.split(":")[1];

      let idx = this.dataTable.dataSet.findIndex(item => item.ID === id);

      let item = this.dataTable.dataSet[idx];

      let dropdown = item.dropdowns.find(n => n.name === column);

      dropdown.value = _value;

      if (this.dropdown(column).multiple) {
        item[column] = dropdown.value.map(e => e.label).join(", ");
      } else {
        item[column] = dropdown.value.label;
      }

      Vue.set(this.dataTable.dataSet, idx, item);
    },

    onInlineTableUpdate(payload) {
      let self = this;

      let data = {
        id: payload.row["ID"]
      };

      if (payload.column === "Contact Name") {
        let s = this.splitContactName(payload["newVal"]);

        data.firstname = s.firstPart;
        data.lastname = s.secondPart;
      }

      if (payload.column === "Email") {
        data.email = payload["newVal"];
      }

      if (payload.column === "Phone") {
        data.phone = payload["newVal"];
      }

      if (payload.column === "Mobile") {
        data.mobile = payload["newVal"];
      }
      if (payload.column === "Title") {
        data.title = payload["newVal"];
      }

      if (payload.column === "Account Name") {
        data.account = payload.row.dropdowns.find(
          n => n.name === "Account Name"
        ).value;
      }
      if (payload.column === "Assigned To") {
        data.assigned_to = payload.row.dropdowns.find(
          n => n.name === "Assigned To"
        ).value;
      }
      if (payload.column === "Country") {
        data.country = payload.row.dropdowns.find(
          n => n.name === "Country"
        ).value;
      }
      if (payload.column === "State") {
        data.state = payload.row.dropdowns.find(n => n.name === "State").value;
      }
      if (payload.column === "City") {
        data.city = payload.row.dropdowns.find(n => n.name === "City").value;
      }

      this.$api
        .put(`contacts/${data.id}`, data)
        .then(response => {
          // this.onColumnUpdate(data.id, payload.column, payload["newVal"]);

          self.$form.makeToastInfo(response.message);
        })
        .catch(function(error) {
          self.$form.makeToastError(error.response.statusText);

          self.getData(self.appliedFilters);
        });
    },
    isNewRecord(row) {
      if (!row) return false;

      return row["ID"] === this.$constants.CUSTOM_TABLE.NEW_ROW_ID;
    },

    validateCell(field, value) {
      if (field === "Contact Name" && value.trim() === "") {
        self.$form.makeToastError("Please enter task name");
        return false;
      }

      return true;
    },

    closeModal: function() {
      this.$refs["preview-modal"].hide();
    },
    viewContent(row) {
      this.previewModal.ID = row["ID"];
      this.previewModal.name = row["Contact Name"];
      this.previewModal.title = row["Title"];
      this.previewModal.department = row["Department"];
      this.previewModal.email = row["Email"];
      this.previewModal.phone = row["Phone"];
      this.previewModal.mobile = row["Mobile"];
      this.previewModal.fax = row["Fax"];

      this.$refs["preview-modal"].show();
    },

    toggleFiles(id) {
      this.$refs.dataTable.toggleChildRow(id);
    },

    onFilter() {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length;
    },
    getDropdowns() {
      let self = this;

      this.$api.get("dictionaries/users").then(response => {
        self.dataTable.options.dropdownColumns.find(
          c => c.name === "Assigned To"
        ).options = response.map(u => ({ id: u.ID, label: u.Name }));

        /*
        self.dropdown("Assigned To").options = response.map(u => ({
          id: u.id,
          label: u.full_name
        }));*/
      });

      this.$api.get("dictionaries/countries/mapped").then(response => {
        self.districts = response;

        self.dropdown("Country").options = self.$helpers.getDistinctArray(
          response,
          "country",
          "country",
          "id",
          "label"
        );
        self.dropdown("State").options = self.$helpers.getDistinctArray(
          response,
          "state",
          "state",
          "id",
          "label"
        );
        self.dropdown("City").options = self.$helpers.getDistinctArray(
          response,
          "city",
          "city",
          "id",
          "label"
        );
      });
    },

    async getData(payload = {}, options) {
      let self = this;

      this.dataTable.isLoading = true;

      //this.dataTable.dataSet = [];

      this.appliedFilters = payload;

      payload.serverOptions = options || self.$refs.dataTable.serverOptions;

      //if filter has been passed through props [ex. Duplicate search table] then override column filters
      if (this.filter.length > 0) {
        payload.serverOptions.filter = this.filter;
        payload.serverOptions.strict_filter = "true";
      }

      //required for prevNext functionaltiy
      this.$customTable.saveAppliedFilters(
        this.$constants.MODULES.Contacts,
        this.appliedFilters
      );

      let response = [];

      try {
        if (this.accountId) {
          response = await this.$api.get(`accounts/${this.accountId}/contacts`);
        } else {
          response = await this.$api.post("contacts", payload);
        }

        self.dataTable.isLoading = false;

        self.$emit("loaded", response.count);

        self.dataTable.dataSet = response.data;

        if (response.count === 0) return;

        if (self.$refs.dataTable)
          self.$refs.dataTable.setTotalPaginationRows(response.count);

        self.updateTableDropdowns();

        //20210131 #316 global search
        //self.$emit("loaded", self.dataTable.dataSet.length)
      } catch (error) {
        console.log(error);
        self.dataTable.isLoading = false;
        self.$form.msgBoxOk("Error occured");
      }
    },
    updateTableDropdowns() {
      let self = this;
      //create array of selected values for multiselect input
      self.dataTable.dataSet.forEach(row => {
        row.dropdowns = [];

        self.dataTable.dropdowns.forEach(dd => {
          let values = [];

          row.dropdowns.push({
            name: dd.name,
            value: []
          });

          if (row[dd.name]) {
            if (row[dd.name].length > 0) {
              // if exists NAME_ID column then get ID otherwise get NAME

              let ids = row[dd.name + "_ID"]
                ? row[dd.name + "_ID"].toString().split(",")
                : row[dd.name].toString().split(",");

              let names = [];
              if (typeof row[dd.name] === "string") {
                names = row[dd.name].split(",");
              }

              for (let i = 0; i < ids.length; i++) {
                values.push({
                  id: ids[i].trim(),
                  label: names[i].trim()
                });
              }
            }

            row.dropdowns.find(n => n.name === dd.name).value = values;
          }
        });
      });
    },
    addItem() {
      this.$router.push({
        name: "Contact submission",
        params: {
          action: "create"
        }
      });
    },
    addItemInline() {
      let newItem = {
        ID: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
        "Contact Name": "",
        Email: "",
        Phone: "",
        Mobile: "",
        "Assigned To": ""
      };

      this.$refs.dataTable.insertNewRow(newItem);

      this.dataTable.isInserting = true;
    },
    splitContactName(str) {
      let result = {
        firstPart: "",
        secondPart: ""
      };

      let spaceIndex = str.indexOf(" ");

      if (spaceIndex > 0) {
        result.firstPart = str.substring(0, spaceIndex);
        result.secondPart = str.substring(spaceIndex + 1, str.length);
      } else {
        result.firstPart = str;
      }

      return result;
    },

    async saveNewRecordCallback(newRecord) {
      let self = this;

      if (newRecord["Contact Name"].trim() === "") {
        self.$form.makeToastError("Please enter Contact Name");
        return false;
      }
      if (
        newRecord["Email"].trim() === "" &&
        newRecord["Phone"].trim() === ""
      ) {
        self.$form.makeToastError("Please enter at least Email OR Phone");
        return false;
      }

      if (newRecord["Assigned To"].trim() === "") {
        self.$form.makeToastError("Please select Assigned To value");
        return false;
      }

      let data = {
        id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID
      };

      let contactParts = this.splitContactName(newRecord["Contact Name"]);
      data.firstname = contactParts.firstPart;
      data.lastname = contactParts.secondPart;

      data.email = newRecord["Email"];
      data.phone = newRecord["Phone"];
      data.mobile = newRecord["Mobile"];
      data.assigned_to = {
        id: newRecord["Assigned To_ID"],
        label: newRecord["Assigned To"]
      };

      return self.$api
        .put("contacts", data)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData(self.appliedFilters);

          self.dataTable.isInserting = false;
        })
        .catch(function(error) {
          self.$form.makeToastError(error.message);

          self.getData(self.appliedFilters);
        });
    },

    async revertNewRecordCallback() {
      this.dataTable.isInserting = false;

      return true;
    },
    addItemQuick() {
      this.$router.push({
        name: "Contact submission quick",
        params: {
          action: "create"
        }
      });
    },
    viewItem(id) {
      this.$router.push({
        name: "Contact submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    editItem(id) {
      this.$router.push({
        name: "Contact submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    async deleteItem(id) {
      let contact = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        `Contact #${contact.ID} will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      //self.dataTable.dataSet = self.dataTable.dataSet.filter(i => i["ID"] !== contact.ID)

      this.$api
        .delete(`contacts/${contact.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData(self.appliedFilters);
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(response.message);
        });
    },
    onInlineUpdate(e) {
      if (e.column === "Contact Name") {
        let contactParts = this.splitContactName(e.value);
        let params = {
          id: e.id,
          firstname: contactParts.firstPart,
          lastname: contactParts.secondPart
        };

        this.updateDatabaseRecord("Contact Name", params);
      } else {
        const columnsMapping = [
          { alias: "Email", dbName: "email" },
          { alias: "Phone", dbName: "phone" },
          { alias: "Mobile", dbName: "mobile" },
          { alias: "Assigned To", dbName: "assigned_to" }
        ];

        let value = e.value;
        let dbName = columnsMapping.find(m => m.alias === e.column).dbName;

        this.updateField(e.id, dbName, value);
      }
    },
    updateField(id, field, value) {
      let params = {};
      params["id"] = id;
      params[field] = value;

      this.updateDatabaseRecord(field, params);
    },
    updateDatabaseRecord(field, params) {
      let self = this;
      this.$api
        .put(`contacts/${params.id}`, params)
        .then(response => {
          self.$form.makeToastInfo(`${field}: ${response.message}`);
        })
        .catch(response => {
          console.log(response);

          self.$form.makeToastError(`${field}: ${response.message}`);
        });
    }
  },
  watch: {}
};
</script>

<style scoped>
::v-deep table .multiselect {
  width: 190px;
}

::v-deep th[id="VueTables_th--Contact Name"] {
  width: 350px;
}

::v-deep table input {
  /* width: 150px;*/
}
</style>
